import React from 'react';
import NewVMStep2 from './NewVMStep2';
import { useQuery } from 'react-query';

const NewVMStep2Container = ({ formikProp, categoryID, sizeData }) => {
  const { data: regionsData } = useQuery('/vs/regions');
  return <NewVMStep2 regionsData={regionsData} formikProp={formikProp} categoryID={categoryID} sizeData={sizeData} />;
};

export default NewVMStep2Container;
