import styled from 'styled-components';
import { variables } from 'variables';
import { keyframes } from 'styled-components';
import { Link } from 'react-router-dom';
import { pStyles } from 'components/reusable-css';

export const AlmaLinuxcPanel = styled.div`
  margin-top: 5px;
  display: flex;
  align-items: center;
  p {
    margin-left: 5px;
    a {
      color: black;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }
`;
export const link = styled(Link)`
  border-bottom: 1px dotted white;
  word-break: break-all;
  font-size: ${(props) => props.theme.default?.p};
  ${(props) => pStyles(props.theme.resolutions)};
  &:hover {
    border-bottom: 0;
    text-decoration: none;
  }
`;
export const ManageVMSC = styled.div`
  background: ${(props) => props.theme.pageBackgroundColor};
  padding: ${variables.pageContentPadding}px;
  padding-bottom: 2em;
  min-height: 100% !important;
  width: 100%;
  height: 100%;
  background-size: cover;
`;

export const toggle = styled.div`
  margin-top: 1.5rem;
  display: flex;
  span {
    font-size: ${(props) => props.theme.default?.p};
    ${(props) => pStyles(props.theme.resolutions)};
  }
  @media only screen and (max-width: 570px) {
    display: block;
  }
`;
export const MobileMenuItem = styled.div`
  padding: 7px;
  &:hover {
    background: ${(props) => props.theme.serverSizeCard.hoverBackgroundColor};
    border-color: ${(props) => props.theme.serverSizeCard.hoverBorderColor};
    cursor: pointer;
  }
`;
export const SerialNo = styled.div`
  padding: 2.5px 30px 20px 0;
  margin-left: -60px;
  font-size: 15px;
  color: black;
`;

export const ManageVMs = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BuildVM = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
`;

export const SearchVMs = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 5px;
`;

export const FlexRow = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: ${(props) => (props.end ? 'flex-end' : 'flex-start')};
`;

export const NoneTag = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: ${(props) => (props.end ? 'flex-end' : 'flex-start')};
`;

export const TagLengthPencil = styled.div`
  i {
    cursor: pointer;
    color: ${(props) => props.theme.primaryDarkColor};
    padding-top: 4px;
    padding-left: 4px;
    outline: 0;
  }
`;

export const OuterTagLength = styled.div`
  p {
    color: black;
    border: 1px solid #e9e9e9;
    border-radius: 5px;
    padding: 4px 8px;
    font-weight: 400;
    margin-right: 6px;
  }
`;

export const NoneTagFlex = styled.div`
  display: flex;
  p {
    font-weight: 500;
  }
  i {
    cursor: pointer;
    color: ${(props) => props.theme.primaryDarkColor};
    padding-left: 5px;
    outline: 0;
  }
`;

export const Flexx = styled.div`
  display: flex;
  p {
    padding-right: 5px;
  }

  i {
    color: ${(props) => props.theme.primaryDarkColor};
    font-size: 12px;
  }
`;
export const FlexColumn = styled.div`
  display: flex;
  flex-flow: column wrap;
  .network-border {
    padding-left: 7px;
    border-left: 1px solid #e9e9e9;
  }
`;
export const SearchField = styled.div`
  margin-right: 7px;
  margin-top: -20px;
  i {
  }
`;

export const Searching = styled.div`
  display: flex;
  justify-content: ${(props) => (props.matches ? 'flex-end' : 'space-between')};
  align-items: center;
  p {
    color: #585858;
    margin: 0;
    margin-right: 10px;
  }
  div {
    display: flex;
    align-items: center;
  }
`;

export const Cardss = styled.div`
  margin-right: 7px;
`;

export const Cards = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Card = styled.div`
  background: white;
  border-radius: 10px;
  border: 2px solid ${(props) => props.theme.serverSizeCard.borderColor};
  margin-top: 15px;
  &:hover {
    background-color: #f9f9f9;
  }
`;

export const CardContent = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 30px 10px 15px 10px;
`;

export const Border = styled.div`
  border-right: 1px solid #e9e9e9;
  margin: 0 0.5rem;
`;

export const StartContent = styled.div`
  display: flex;
  width: 40%;
`;

export const MobilePicture = styled.div`
  padding: 2rem 1rem 2rem 1rem;
  display: flex;
  justify-content: center;
  i {
    color: ${(props) => props.theme.primaryDarkColor};
  }
  @media only screen and (min-width: 400px) {
    padding: 2rem;
  }
  @media only screen and (min-width: 370px) {
    padding: 2rem 1.8rem 2rem 1.8rem;
  }
  @media only screen and (max-width: 335px) {
    padding: 2rem 0.6rem 2rem 0.6rem;
  }
  @media only screen and (max-width: 323px) {
    padding: 2rem 0.5rem 2rem 0.5rem;
  }
`;

export const Picture = styled.div`
  padding: 0 2rem;
  display: flex;
  justify-content: center;

  i {
    color: ${(props) => props.theme.primaryDarkColor};
  }
`;

export const MobileOptions = styled.div`
  background-color: white;
  border-radius: 15px;
  width: 20rem;
  margin-top: -7rem;
  z-index: 1111;
  @media only screen and (max-width: 380px) {
    width: 18rem;
  }
  @media only screen and (max-width: 340px) {
    width: 16rem;
  }
`;

export const Backup = styled.div`
  padding: 0px 20px 15px 20px;
  color: black;
  width: 200px;
  @media only screen and (max-width: 900px) {
    padding: 20px 20px 15px 10px;
  }
  p {
    margin: 0;
    font-size: 15px;
    padding-bottom: 6px;
  }
  // span {
  //   color: #585858;
  //   font-size: 13px;
  // }
`;

export const SummaryLastRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5px;
  @media only screen and (max-width: 500px) {
    display: block;
  }
`;

export const SettingIcon = styled.div`
  display: flex;
  padding: 0px 10px 20px 5px;
  align-items: center;
  i.refresh {
    opacity: ${(props) => (props.loading ? 0.5 : 1)};
    pointer-events: ${(props) => (props.loading ? 'none' : '')};
  }
  i {
    margin-right: 2.1rem;
    color: #585858;
    cursor: pointer;
    color: ${(props) => props.theme.primaryDarkColor};
  }
  svg {
    margin-right: 2.1rem;
    color: #585858;
    cursor: pointer;
    color: ${(props) => props.theme.primaryDarkColor};
  }
`;

export const More = styled.div`
  padding: 0px 30px 12px 0px;
  margin-top: -7px;
  @media only screen and (max-width: 900px) {
    padding: 25px 10px 12px 0px;
  }
`;

export const Adding = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 0.8rem;
  align-self: ${(props) => (props.center ? 'center' : 'flex-start')};
  div {
    display: flex;
  }
`;

export const InputTag = styled.div`
  margin-top: -20px;
  i {
    color: orange;
    font-size: 15px;
    padding: 15px 0 0 10px;
    margin-top: 20px;
    cursor: pointer;
  }
`;

export const Label = styled.div`
  display: flex;
  @media only screen and (min-width: 2300px) {
    width: 50rem;
  }
  @media only screen and (max-width: 2299px) {
    width: 40rem;
  }
  @media only screen and (max-width: 1900px) {
    width: 30rem;
  }
  @media only screen and (max-width: 1660px) {
    width: 25rem;
  }
  @media only screen and (max-width: 959px) {
    width: 100%;
  }
  .mobile {
    @media only screen and (max-width: 700px) {
      width: 30rem;
    }
    @media only screen and (max-width: 590px) {
      width: 25rem;
    }
    @media only screen and (max-width: 590px) {
      width: 13rem;
    }
    h5 {
      font-weight: 400;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  h5 {
    font-weight: 400;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  i {
    color: ${(props) => props.theme.primaryDarkColor};
    font-size: 15px;
    padding: 3px 0 0 10px;
    cursor: pointer;
  }
`;

export const AddTag = styled.div`
  max-height: ${(props) => (props.twoRows ? '5.5rem' : 'auto')};
  overflow: auto;
  margin-bottom: 8px;
`;

export const PasswordLink = styled.div`
  margin-bottom: 0.5rem;
  p {
    color: #007bff;
    margin: 0;
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const Country = styled.div`
  padding: 0px 20px 15px 0px;
  width: 200px;
`;

export const Network = styled.div`
  display: flex;
  padding-top: 8px;
  @media only screen and (max-width: 768px) {
    flex-flow: row wrap;
  }

  p {
    color: #585858;
    font-size: 12px;
    margin-bottom: 4px;
  }
`;

export const Circle = styled.div`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  overflow: hidden;
`;

export const CountryFlag = styled.div`
  display: flex;
  align-items: flex-start;
  img {
    object-fit: contain;
  }
  h6 {
    margin: 5px 15px 0 8px;
    white-space: nowrap;
  }
`;

export const CountryFlagMobile = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  img {
    object-fit: contain;
  }
  h6 {
    padding: 5px 15px 0 8px;
  }
  @media only screen and (max-width: 500px) {
    justify-content: flex-start;
    margin-top: 7px;
  }
`;

const refresh_animation = keyframes`
    from {
     opacity: 0.4;
    }

    to {
     opacity: 1;
    }
`;

export const Status = styled.p`
  color: white;
  border: 1px solid transparent;
  background: ${(props) =>
    !props.status
      ? props.theme.serverSizeCard.saleRed.backgroundColor
      : props.status === 'off' || props.status === 'stopping' || props.status === 'restoring'
      ? props.theme.serverSizeCard.tag.stopBackgroundColor
      : props.theme.serverSizeCard.tag.backgroundColor};
  border-radius: 3px;
  padding: 2px 5px;
  font-size: 12px;
  animation-name: ${(props) => (props.managerVmIsFetching ? refresh_animation : '')};
  animation-duration: 1s;
`;

export const RedStatus = styled.p`
  color: #f3a3a3;
  border: 1px solid transparent;
  background: #fceaea;
  border-radius: 3px;
  padding: 2px 5px;
  font-size: 12px;
`;

// Tags

export const FilterCards = styled.div`
  color: black;
  border: 1px solid transparent;
  background: white;
  border-radius: 15px;
  padding: 15px 10px 13px 5px;
  i {
    cursor: pointer;
  }
  @media only screen and (min-width: 500px) {
    width: 29em;
  }
`;

export const MobileCardDescription = styled.div`
  display: flex;
  padding: 0.5rem 0;
`;

export const TagContent = styled.div`
  padding: 0px 20px;
  margin-top: -10px;
`;

export const PasswordRetriveCards = styled.div`
  color: black;
  border: 1px solid transparent;
  background: white;
  border-radius: 15px;
  padding: 15px 10px 13px 5px;
  @media only screen and (min-width: 500px) {
    width: 45em;
  }
  i {
    cursor: pointer;
  }
`;

export const PasswordRetriveContent = styled.div`
  margin: 1.5em 0;
  h6 {
    margin: 0;
    margin-right: 1em;
  }

  .list {
    display: flex;
    align-items: center;
    margin-bottom: 1em;
    .ip {
      width: 10em;
    }
  }
`;

export const GreyBorder = styled.div`
  border-bottom: 1px solid #b4b4b4;
  padding: 8px 0;
`;

export const CrossTag = styled.div`
  display: flex;
  align-items: center;
  flex-flow: row wrap;
`;

export const CurrentTag = styled.div`
  pointer-events: ${({ loading }) => (loading ? 'none' : 'auto')};
  i {
    font-size: 12.5px;
    position: absolute;
    top: 1px;
    float: right;
    right: -3px;
    cursor: pointer;
    color: orange;
    :hover {
      color: orange;
    }
    opacity: ${({ loading }) => (loading ? 0.3 : 1)};
  }
  div.tag {
    background: ${(props) => props.theme.panelBackground};
    opacity: ${({ loading }) => (loading ? 0.7 : 1)};
    color: black;
    border: 1px solid #cecece;
    border-radius: 5px;
    padding: 6px 18px 6px 10px;
    cursor: pointer;
    margin-right: 7px;
    margin-bottom: 8px;
    height: 35px;
    :hover {
      border-bottom: ${(props) => (props.isHover ? '2.9px solid orange' : '2.9px solid #cecece')};
    }
  }
  p {
    font-size: 14px;
    font-weight: 400;
    margin: 0;
    opacity: ${({ loading }) => (loading ? 0.3 : 1)};
  }
  &.disable {
    pointer-events: none;
    /* opacity: 0.3; */
  }
`;

export const CurrentTagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const GreyTag = styled.div`
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  padding-bottom: 5px;

  p {
    background: ${(props) => props.theme.panelBackground};
    border: 1px solid #cecece;
    color: black;
    border-radius: 5px;
    padding: 6px 10px;
    font-size: 14px;
    font-weight: 400;
    margin: 0;
    margin-right: 7px;
    margin-bottom: 8px;
    cursor: pointer;
    :hover {
      border-bottom: 1px solid orange;
    }
  }
`;

export const NewTag = styled.div`
  margin-top: -25px;
  i {
    color: orange;
    font-size: 14px;
    /* position: relative; */
    /* top: -37px;
    right: 10px;
    float: right; */
    cursor: pointer;
  }
`;

// SortBy

export const SortBy = styled.div`
  color: black;
  width: 29em;
  border: 1px solid transparent;
  background: white;
  border-radius: 15px;
  padding: 15px 10px 13px 5px;

  @media only screen and (max-width: 425px) {
    width: 26em;
  }

  @media only screen and (max-width: 375px) {
    width: 24em;
  }

  @media only screen and (max-width: 320px) {
    width: 21.5em;
  }
`;

export const Buttons = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  padding: 20px 0 10px 0;
`;

export const StatusButtons = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
`;

export const Button = styled.button`
  background: white;
  border: 1px solid #b4b4b4;
  color: grey;
  padding: 4px 16px;
  font-weight: bold;
  font-size: ${(props) => props.theme.default?.p};
  ${(props) => pStyles(props.theme.resolutions)};
  border-radius: 5px;
  outline: 0;
  span {
    font-weight: 500;
    font-size: ${(props) => props.theme.default?.p};
    ${(props) => pStyles(props.theme.resolutions)};
    color: black;
  }
`;

// FilterBy

export const FiltersCards = styled.div`
  color: black;
  border: 1px solid transparent;
  background: white;
  border-radius: 15px;
  padding: 20px 10px 20px 5px;
  i {
    font-size: 16px;
    cursor: pointer;
  }
`;
export const Flex = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  padding: 15px 0;
`;

export const FilterContent = styled.div`
  padding: 0px 20px;
  padding-top: 10px;
`;

export const SelectTag = styled.div`
  display: flex;
  flex-flow: row wrap;
  i {
    padding-right: 5px;
  }
  p {
    color: black;
    text-transform: capitalize;
  }
`;

export const Select = styled.div`
  display: flex;
  flex-flow: row wrap;
  p {
    color: black;
    text-transform: capitalize;
  }
`;

export const CountryTag = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  background: ${(props) => props.theme.panelBackground};
  border: 1px solid #cecece;
  border-radius: 5px;
  padding: 6px 7px;
  margin-right: 8px;
  margin-bottom: 8px;
  cursor: pointer;

  i {
    color: ${(props) => props.theme.primaryDarkColor};
    font-size: 22px;
  }
`;

export const NewTagButton = styled.span`
  background-color: ${(props) => props.theme.primaryDarkColor};
  font-size: 12px;
  margin-left: 5px;
  color: white;
  height: 22.5px;
  padding: 2px 6px;
  font-weight: 900;
  font-style: italic;
  border-radius: 5px;
`;

export const CountryHeading = styled.div`
  color: #000;
  padding-left: 12px;
  text-transform: capitalize;
  h6 {
    margin: 0;
    font-weight: bold;
  }
`;

export const CustomTagStyles = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
`;

//vm-bm-not-found

export const NotFound = styled.div`
  background: ${(props) => props.theme.panelBackground};
  padding: ${variables.pageContentPadding}px;
  padding-bottom: 2em;
  min-height: 100% !important;
  width: 100%;
  height: 100%;
  background-size: cover;
`;

export const UndoDelete = styled.div`
  font-size: 12px;
`;

export const ServerImage = styled.div`
  position: relative;
  .disable {
    pointer-events: none;
  }

  .popup {
    position: absolute;
    top: -11px;
    left: -31px;
    width: 100%;
    bottom: auto;
  }
  .popup.x-error {
    top: 0px;
    left: -30px;
  }
  .os_updates_available {
    padding: 3px 5px;
    line-height: normal;
    text-align: center;
    font-size: 11px;
    font-weight: 600;
    width: 42px;
    border-color: #fff;
    box-shadow: -1px -1px 3px 0 #adadad;
    cursor: pointer;
    color: white;
    display: flex;
    justify-content: center;
    background-color: ${(props) => props.theme.serverSizeCard.tag.backgroundColor};
    padding: 10;
    &.update {
      width: 65px !important;
    }
    &.windows {
      cursor: auto;
      text-decoration: none;
    }
  }
  .os_updates_available.os_error {
    background-color: ${(props) => props.theme.redColor};
    width: 20px;
  }
  .os_updates_available:hover {
    text-decoration: underline;
  }
  .windows:hover {
    text-decoration: none;
  }
  .popup-content {
    background: ${(props) => props.theme.serverSizeCard.tag.backgroundColor};
    border: 1px solid white;
  }
`;
