import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Icon } from 'semantic-ui-react';
import * as Styles from 'components/vm-deployment/fund-card/FundCard.style';
import { totalPrice, vsPriceOnly } from 'components/vm-deployment/util';
import priceCalculator from 'utils/price-calculator';
import { BmHooks } from 'hooks';
import { P } from 'components/reusable-css';
import { useSelector } from 'react-redux';

export default function PaymentCard({ data, selected = false, onClick, formikProp }) {
  const { t } = useTranslation();
  const userAccount = useSelector((state) => state.user.account);
  const { isBmAmountAvailableToTotal } = BmHooks();
  const isFunds = isBmAmountAvailableToTotal(formikProp?.values);
  return (
    <Styles.ServerSizeCardSC selected={selected} onClick={() => onClick(data)}>
      <Styles.Tag showTag={data.price_times_months == 12}>
        {data.price_times_months == 12 ? t('bm/instance/create.step6_string16') : null}
      </Styles.Tag>

      {data.free_month ? (
        data.free_month == 0 ? (
          <div>
            <Styles.NameContainer>
              <Styles.Name>{t('bm/instance/create.step6_string5')}</Styles.Name>
            </Styles.NameContainer>
            <Styles.Price>
              <Styles.PerMonth>
                <Trans
                  i18nKey="bm/instance/create.step6_string8"
                  values={{
                    currency_symbol: userAccount.account_currency_symbol,
                    price: priceCalculator(totalPrice(formikProp?.values?.prices), false, true),
                  }}
                  components={{
                    price: <Styles.PriceUnit />,
                  }}
                />
              </Styles.PerMonth>
            </Styles.Price>
            <Styles.Features>
              <Styles.Feature>
                <Icon name="check" />
                {isFunds ? t('bm/instance/create.step6_string10') : t('bm/instance/create.step6_string11')}
              </Styles.Feature>
            </Styles.Features>
          </div>
        ) : null
      ) : data.free_base_price_month ? (
        data.price_times_months == 1 ? (
          <div>
            <Styles.NameContainer>
              <Styles.Name>{t('bm/instance/create.step6_string6')}</Styles.Name>
            </Styles.NameContainer>
            <Styles.Price>
              <Styles.PerMonth>
                <Trans
                  i18nKey="bm/instance/create.step6_string9"
                  values={{
                    currency_symbol: userAccount.account_currency_symbol,
                    bounus_price: priceCalculator(vsPriceOnly(formikProp?.values?.prices), true, true),
                    price: priceCalculator(totalPrice(formikProp?.values?.prices) * 6, false, true),
                  }}
                  components={{
                    price: <Styles.PriceUnit />,
                    add: <Styles.Add />,
                    bonus: <P />,
                  }}
                />
              </Styles.PerMonth>
            </Styles.Price>
            <Styles.Features>
              <Styles.Feature>
                <Icon name="check" />
                {t('bm/instance/create.step6_string12')}
              </Styles.Feature>
              <Styles.Feature>
                <Icon name="check" />
                {t('bm/instance/create.step6_string13')}
              </Styles.Feature>
              <Styles.Feature>
                <Icon name="check" />
                {t('bm/instance/create.step6_string14')}
              </Styles.Feature>
            </Styles.Features>
          </div>
        ) : data.price_times_months == 12 ? (
          <div>
            <Styles.NameContainer>
              <Styles.Name>{t('bm/instance/create.step6_string7')}</Styles.Name>
            </Styles.NameContainer>
            <Styles.Price>
              <Styles.PerMonth>
                <Trans
                  i18nKey="bm/instance/create.step6_string9"
                  values={{
                    currency_symbol: userAccount.account_currency_symbol,
                    bounus_price: priceCalculator(vsPriceOnly(formikProp?.values?.prices) * 2, true, true),
                    price: priceCalculator(totalPrice(formikProp?.values?.prices) * 12, false, true),
                  }}
                  components={{
                    price: <Styles.PriceUnit />,
                    add: <Styles.Add />,
                    bonus: <P />,
                  }}
                />
              </Styles.PerMonth>
            </Styles.Price>
            <Styles.Features>
              <Styles.Feature>
                <Icon name="check" />
                {t('bm/instance/create.step6_string17')}
              </Styles.Feature>
              <Styles.Feature>
                <Icon name="check" />
                {t('bm/instance/create.step6_string13')}
              </Styles.Feature>
              <Styles.Feature>
                <Icon name="check" />
                {t('bm/instance/create.step6_string14')}
              </Styles.Feature>
            </Styles.Features>
          </div>
        ) : null
      ) : null}

      {selected && (
        <Styles.SelectedTag>
          <Icon name="check" />
          {t('bm/instance/create.step6_string15')}
        </Styles.SelectedTag>
      )}
    </Styles.ServerSizeCardSC>
  );
}
