import React from 'react';
import { Icon } from 'semantic-ui-react';
import { ErrorSC, ErrorMsg } from './ErrorMessage.style';
import { useTranslation } from 'react-i18next';

const ErrorMessage = ({ text, style, textStyles }) => {
  const { t } = useTranslation();
  return (
    <ErrorSC style={style}>
      <Icon name="warning circle" />
      <ErrorMsg>
        <p style={textStyles}>{t(text || 'string8')}</p>
      </ErrorMsg>
    </ErrorSC>
  );
};

export default ErrorMessage;
