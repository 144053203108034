import { h4Styles, h5Styles, h6Styles, pStyles } from 'components/reusable-css';
import styled, { css } from 'styled-components';

export const SettingPanel = styled.div`
  background: ${(props) => props.theme.panelBackground};
  min-height: 100%;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  padding: 30px 0 0 0;
  background: white;
`;
export const Start = styled.div`
  padding: 10px 30px;
  @media (max-width: 768px) {
    padding: 10px 20px;
  }
`;
export const Heading = styled.div`
  display: flex;
  align-items: center;
`;
export const Text = styled.h6`
  margin: 0;
  font-weight: 490;
  font-size: ${(props) => props.theme.default?.h6};
  ${(props) => h6Styles(props.theme.resolutions)};
`;
export const CrossIcon = styled.div`
  i {
    color: ${(props) => props.theme.primaryDarkColor};
    cursor: pointer;
    margin-right: 20px;
  }
`;
export const Picture = styled.div`
  margin-right: 20px;
  i {
    font-size: 26px;
    color: ${(props) => props.theme.primaryDarkColor};
  }
`;
export const Country = styled.div`
  padding-left: 30px;
  display: flex;
  align-items: center;
`;
export const Circle = styled.div`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 8px;
`;

export const Options = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
`;
export const Option = styled.div`
  padding: 10px 15px;
  margin: 6px 6px 6px 0;
  border-radius: 5px;
  cursor: pointer;
  background-color: ${(props) => (props.activePanelValue == props.menuOptionId ? '#FF7300' : '#ebebeb')};
  color: ${(props) => (props.activePanelValue == props.menuOptionId ? 'white' : 'black')};
  position: relative;
  .tricut-image {
    position: absolute;
    bottom: -1px;
    right: 45%;
    width: 15px;
    transform: rotate(180deg);
  }
`;

/// OptionsHeading
export const OptionTitle = styled.h6`
  font-size: ${(props) => props.theme.default?.h6};
  ${(props) => h6Styles(props.theme.resolutions)};
  font-weight: 600;
  color: black;
`;

export const OptionText = styled.p`
  font-size: ${(props) => props.theme.default?.p};
  ${(props) => pStyles(props.theme.resolutions)};
  color: black;
`;
export const DestroyText = styled.p`
  font-size: ${(props) => props.theme.default?.p};
  ${(props) => pStyles(props.theme.resolutions)};
  color: black;
  margin-bottom: 5px;
`;

//----- Info / Ips

export const InfoDetail = styled.div`
  display: flex;
  padding: 30px 30px;
  @media (max-width: 1200px) {
    flex-wrap: wrap;
  }
  background: ${(props) => props.theme.panelBackground};
  .first {
    width: 50%;
    border-right: 2px solid lightgray;
    padding-right: 1em;
    @media (max-width: 1200px) {
      width: 100%;
      border-right: 0px solid lightgray;
    }
  }
  .second {
    width: 50%;
    margin-top: 1em;
    padding-left: 1em;
    @media (max-width: 1200px) {
      width: 100%;
      padding-left: 0em;
      margin-top: 0em;
    }
  }
  @media (max-width: 960px) {
    padding: 15px 10px;
  }
`;

export const AddingIP = styled.div`
  display: flex;
  flex-direction: column;
  margin: 5px 0;
  @media (max-width: 960px) {
    margin-bottom: 20px;
  }
`;

export const PublicBottomMargin = styled.div`
  margin-top: 1.4em;
  @media (min-width: 1920px) {
    margin-top: 0;
  }
`;

export const PublicMargin = styled.div`
  margin-top: 1.4em;
`;

export const PublicMarginLeft = styled.div`
  margin-left: 1.8em;
  @media (min-width: 1920px) {
    margin-bottom: 1.4em;
  }
`;

export const IPText = styled.h6`
  color: black;
  padding: 5px 0;
  word-break: break-all;
  margin: 0;
  font-size: ${(props) => props.theme.default?.h6};
  ${(props) => h6Styles(props.theme.resolutions)};
  @media (max-width: 960px) {
    padding: 0;
    padding-bottom: 20px;
  }
`;

export const Feature = styled.div`
  display: flex;
  line-height: 2em;
  font-size: ${(props) => props.theme.default?.p};
  ${(props) => pStyles(props.theme.resolutions)};
  i {
    color: ${(props) => props.theme.primaryColor};
    margin-right: 6px;
    margin-top: -12px;
  }
  @media only screen and (max-width: 959px) {
    margin-right: 6px;
    margin-bottom: 10px;
    line-height: 1.2em;
  }
`;

export const Features = styled.div`
  flex: 1;
  padding: 1em 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background: ${(props) => props.theme.serverSizeCard.features.backgroundColor};
  @media only screen and (max-width: 649px) {
    padding: 1em 15px 3em 15px;
  }
`;

export const ServerSizeCardSC = styled.div`
  min-width: 300px;
  width: 100%;
  background: ${(props) => props.theme.gray.shadeF};
  border-radius: 10px;
  border: 2px solid ${(props) => props.theme.serverSizeCard.borderColor};
`;

export const FlexCon = styled.div`
  padding: 1em 0;
  padding-left: 10px;
`;

export const Name = styled.div`
  font-size: ${(props) => props.theme.default?.h4};
  ${(props) => h4Styles(props.theme.resolutions)};
`;

export const NameContainer = styled.div`
  padding-left: 10px;
`;

export const Section = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1em;
  h6 {
    margin: 0;
  }
  .section {
    @media (max-width: 850px) {
      width: 100%;
      margin: 0.5em 0 1em 1.8em;
    }
  }
  .width {
    width: 20em !important;
    @media (max-width: 850px) {
      width: 100%;
    }
  }
  .margin {
    margin-left: -4px;
    @media (max-width: 850px) {
      width: 100%;
      margin: 0.5em 0 1em 1.5em;
    }
  }
  @media (max-width: 850px) {
    flex-wrap: wrap !important;
  }
`;

export const IPTitle = styled.h6`
  font-size: ${(props) => props.theme.default?.h6};
  ${(props) => h6Styles(props.theme.resolutions)};
  font-weight: 600;
  color: black;
  padding: 15px 0;
  @media (max-width: 960px) {
    padding: 0;
    padding-bottom: 2px;
  }
`;

export const Para = styled.p`
  font-size: 17px;
  color: black;
  padding-top: 12px;
  @media (max-width: 960px) {
    padding-top: 0px;
  }
`;
export const PublicIPPara = styled.p`
  font-size: ${(props) => props.theme.default?.p};
  ${(props) => pStyles(props.theme.resolutions)};
  color: black;
  padding: 5px 0;
`;

export const AddIP = styled.div`
  display: flex;
`;

export const IPBackground = styled.div`
  display: flex;
  align-items: center;
  border: 2px solid #e8e8e8;
  padding: 5px 12px;
  border-radius: 8px;
  background-color: white;
  margin-right: 8px;
  margin-bottom: 8px;
  margin-top: 10px;
`;

// Actions ---------------

export const ActionDetail = styled.div`
  margin-top: 15px;
  padding: 15px 0 45px 0;
  display: flex;
`;

export const ActionDiv = styled.div`
  background: white;
  @media (max-width: 960px) {
    margin: -15px;
    padding: 25px 20px;
  }
`;

export const Action1 = styled.div`
  display: flex;
  flex-direction: column;
  width: 22%;
  padding: 20px 10px 80px 5px;
  margin: 0 30px;
  border: 2px solid #e8e8e8;
  border-radius: 8px;
  background: white;
  @media (max-width: 1185px) {
    width: 29%;
  }
  @media (min-width: 1440px) {
    width: 20%;
  }
  @media (min-width: 2000px) {
    width: 13%;
  }
`;
export const Action2 = styled.div`
  display: flex;
  flex-direction: column;
  width: 78%;
  padding: 20px 32px 80px 20px;
  margin-right: 40px;
  border: 2px solid #e8e8e8;
  border-radius: 8px;
  background: white;
  @media (max-width: 1185px) {
    width: 71%;
  }
  @media (min-width: 1440px) {
    width: 80%;
  }
  @media (min-width: 2000px) {
    width: 87%;
  }
`;
export const BottomBorder = styled.div`
  border: 2px solid #e8e8e8;
  border-radius: 8px;
  width: 88%;
  margin: 2px auto 5px auto;
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
`;
export const InputName = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 10px;
`;
export const SelectName = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  @media (max-width: 1024px) {
    flex-wrap: wrap;
  }
  @media (max-width: 960px) {
    flex-wrap: no-wrap;
  }
`;

export const ActionOptions = styled.div`
  display: flex;
  flex-direction: column;
`;
export const ActionText = styled.div`
  display: flex;
  padding: 0 2px 15px 10px;
  border-radius: 5px;
  cursor: pointer;
  i {
    font-size: 10px;
    margin-top: 3.5px;
    color: ${(props) => (props.isShow ? '#FF7300' : 'black')};
  }
`;

export const ActionOption = styled.div`
  margin: 0 6px;
  color: ${(props) => (props.isShow ? '#FF7300' : 'black')};
  font-size: ${(props) => props.theme.default?.p};
  ${(props) => pStyles(props.theme.resolutions)};
`;

//upgrade
export const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 18px;
  @media (max-width: 959px) {
    margin: -10px -16px;
    margin-top: 10px;
  }
`;
export const Background = styled.div`
  background: ${(props) => props.theme.panelBackground};
`;
export const ServerSizeGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 10px;
  padding: 20px 30px;
  padding-top: 0;
  @media (max-width: 600px) {
    padding: 5px;
  }
`;
export const FundGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 10px;
  padding: 20px 30px;
  @media (max-width: 768px) {
    padding: 20px;
  }
`;
export const Padding = styled.div`
  padding: 20px 30px;
  @media (max-width: 768px) {
    padding: 20px;
  }
`;
export const OrangeBorder = styled.div`
  border-bottom: 4px solid ${(props) => props.theme.primaryDarkColor};
`;

export const NotificationContainer = styled.div`
  display: flex;
  margin: 50px 0;
`;

export const Step3 = styled.div`
  display: flex;
  flex-direction: column;
  margin: 40px 0 30px 0;
  @media (max-width: 1316px) {
    margin: 20px 0 5px 0;
  }
`;
export const Step3Margin = styled.div`
  margin: 40px 0 5px 0;
  @media (max-width: 1316px) {
    margin-top: 20px;
  }
`;
export const VSOption = styled.div`
  border-radius: 5px;
  padding: 10px 0px;
  display: flex;
  flex-direction: column;
`;

export const Flex = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 5px 0;
`;
export const VSOptions = styled.div`
  margin-right: 20px;
  margin-bottom: 8px;
  display: flex;
  p {
    margin-left: -23px;
  }
`;

//Reverse DNS

export const ReverseSC = styled.div`
  padding: 30px 30px;
  display: flex;
  flex-direction: column;
  @media (max-width: 959px) {
    padding: 15px 10px;
  }
  @media (max-width: 375px) {
    padding: 15px 0;
  }
`;
export const ReverseDNS = styled.div`
  padding-top: 15px;
  @media (max-width: 959px) {
    padding-top: 5px;
  }
`;
export const IP = styled.h6`
  font-size: ${(props) => props.theme.default?.h6};
  ${(props) => h6Styles(props.theme.resolutions)};
  font-weight: 600;
  color: black;
  display: flex;
  justify-content: flex-end;
  padding: 25px 0;
  text-align: right;
  @media (max-width: 959px) {
    padding: 0;
    padding-top: 18px;
    text-align: left;
    justify-content: flex-start;
  }
`;
export const DNS = styled.h6`
  font-size: ${(props) => props.theme.default?.h6};
  ${(props) => h6Styles(props.theme.resolutions)};
  white-space: nowrap;
  font-weight: 600;
  color: black;
  padding: 15px 0;
  text-align: right;
  text-wrap: none;
  @media (max-width: 959px) {
    padding: 0;
    padding-top: 5px;
    text-align: left;
  }
`;
export const Spacing = styled.div`
  padding-right: 3rem;
  @media (max-width: 959px) {
    padding: 0;
  }
`;

//Template-backups

export const TemplateSC = styled.div`
  padding: 30px 30px;
  display: flex;
  flex-direction: column;
  @media (max-width: 959px) {
    padding: 15px 10px 10px 10px;
  }
  @media (max-width: 375px) {
    padding: 15px 0 10px 0;
  }
`;

export const BackupBg = styled.div`
  background-color: ${(props) => props.theme.primaryDarkColor};
  border-radius: 8px;
  padding: 20px;
  color: white;
`;
export const BackupSc = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 1280px) {
    img {
      width: 90%;
    }
  }
  @media (max-width: 959px) {
    flex-direction: column;
    align-items: flex-start;
    img {
      margin-bottom: 8px;
      width: 70%;
    }
  }
`;
export const BackupPlan = styled.div`
  padding-top: 30px;
  padding-bottom: 20px;
  margin: 0 -29px;
  border-bottom: 4px solid ${(props) => props.theme.primaryDarkColor};
  @media (max-width: 959px) {
    margin: 0 -26px;
  }
  @media (max-width: 375px) {
    margin: 0 -15px;
  }
`;
export const Align = styled.div`
  padding: 0 30px 0 13px;
`;
export const BackupPadding = styled.div`
  padding-left: 3.7em;
  @media (max-width: 959px) {
    padding-left: 3.3em;
  }
  @media (max-width: 700px) {
    padding-left: 3em;
  }
  @media (max-width: 600px) {
    padding-left: 2.5em;
  }
  @media (max-width: 400px) {
    padding-left: 2em;
  }
`;
export const BackupCardPadding = styled.div`
  padding-left: 1.6em;
  padding-bottom: 1.5em;
  @media (max-width: 959px) {
    padding-left: 1.2em;
  }
  @media (max-width: 700px) {
    padding-left: 10px;
  }
  @media (max-width: 600px) {
    padding-left: 2em;
  }
  @media (max-width: 400px) {
    padding-left: 10px;
  }
`;
export const Paragraph = styled.div`
  padding: 1.5em 0;
`;
export const DailyBackup = styled.div`
  padding-top: ${({ billing_schedule }) => (billing_schedule ? '30px' : '0')};
  padding-bottom: 20px;
  margin: 0 -29px;
  background: ${({ billing_schedule, theme }) => (billing_schedule ? 'white' : theme.panelBackground)};
  border-bottom: 4px solid ${(props) => props.theme.primaryDarkColor};
  @media (max-width: 959px) {
    padding: 10px 0;
    margin: 0 -26px;
  }
  @media (max-width: 375px) {
    margin: 0 -15px;
  }
`;
export const PermanentBackup = styled.div`
  padding-top: 30px;
  margin: 0 -29px;
  @media (max-width: 959px) {
    padding-top: 10px;
    margin: 0 -26px;
  }
  @media (max-width: 375px) {
    margin: 0 -15px;
  }
`;
export const NoBackupCard = styled.div`
  border: 2px solid #e3e6e4;
  padding: 20px;
  border-radius: 8px;
  background: white;
  color: ${(props) => props.theme.primaryDarkColor};
`;
export const BackupCard = styled.div`
  h5 {
    font-size: ${(props) => props.theme.default?.h5};
    ${(props) => h5Styles(props.theme.resolutions)};
  }
  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .backupCardHeader {
    display: flex;
    padding: 0 1rem;
  }

  .backupCard {
    border: 2px solid #e3e6e4;
    border-radius: 10px;
    padding: 1rem;
    display: flex;
    margin: 10px 0;
    width: 100%;
    background: white;
    i {
      color: ${(props) => props.theme.primaryDarkColor};
      cursor: pointer;
    }
  }
`;

export const RestoreConfirmation = styled.div`
  color: black;
  border: 1px solid transparent;
  background: white;
  border-radius: 15px;
  padding: 20px 10px 10px 5px;
  i {
    font-size: 16px;
    cursor: pointer;
  }
`;

export const RestoreContent = styled.div`
  padding: 0px 20px;
`;

export const Buttons = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 10px 0;
`;

export const CardContainer = styled.div`
  margin-top: 1em;
  display: flex;
  @media only screen and (max-width: 1375px) {
    flex-wrap: wrap;
  }
`;

export const CardContainers = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 0.5rem;
  @media only screen and (max-width: 1375px) {
    width: 48%;
  }
`;

export const TriangleLine = styled.div`
  border-radius: 15px;
  padding-top: 1.5em;
  .line {
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 20px solid ${(props) => (props.selected ? 'orange' : 'transparent')};
  }
  .doubleline {
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 20px solid ${(props) => (props.selected ? props.theme.pageBackgroundColor : 'transparent')};
    margin-top: -1.1em;
  }

  @media only screen and (max-width: 1375px) {
    display: none;
  }
`;

export const Line = styled.div`
  border-top: 2px solid orange;
  margin-right: 18px;
  border-radius: 15px;
  padding-bottom: 2.5em;
  margin-top: -5px;
  @media only screen and (max-width: 1375px) {
    display: none;
  }
`;

export const Card = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
  cursor: pointer;
  padding: 1em;
  padding-right: 2em;
  border: 2px solid ${(props) => props.theme.serverSizeCard.borderColor};
  border-radius: 8px;
  margin-bottom: 0.5rem;
  ${(props) =>
    props.selected &&
    css`
      background-color: ${(props) => props.theme.primaryDarkColor};
      border: 2px solid ${(props) => props.theme.primaryDarkColor};
      color: white;
    `};
`;
