import React from 'react';
import { RadioCheck, Input } from './RadioButton.style';
import { isChecked } from './util.js';
const Checkbox = (props) => {
  const {
    checkbox,
    data,
    setCheckedValue = () => {},
    onChange = () => {},
    id,
    type,
    name,
    checked,
    className,
    onClick,
    field,
  } = props;
  return (
    <RadioCheck
      onClick={() => {
        setCheckedValue(data?.id);
        onClick && onClick();
      }}
    >
      <Input
        type={type || 'checkbox'}
        name={name || 'check'}
        id={id}
        checked={isChecked(checkbox, data, checked, field)}
        onChange={onChange}
        onClick={(e) => {
          e.stopPropagation();
          setCheckedValue(data?.id);
          onClick && onClick();
        }}
        className={className}
      />
    </RadioCheck>
  );
};

export default Checkbox;
