/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-01-27 17:35:28
 * @modify date 2021-01-27 17:35:29
 */

import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import rootReducer from 'store/reducers';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['commonStates', 'vmDeployment', 'bmDeployment', 'vsCart', 'domainCart'],
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

const middleware = [thunk];

export default () => {
  let store = createStore(persistedReducer, applyMiddleware(...middleware));
  let persistor = persistStore(store);
  return { store, persistor };
};
