import React from 'react';
import * as Styles from 'pages/member/manage-vm/ManageVM.style';
import { Network } from 'components/manage-vm/components';

const BmNetworks = ({ data }) => {
  return (
    <Styles.Network>
      <Styles.FlexColumn>
        {data.networks.map((network) => {
          return network.ipv4?.map((ip) => <Network ip={ip} key={ip.ip} />);
        })}
      </Styles.FlexColumn>
    </Styles.Network>
  );
};
export { BmNetworks };
