import React from 'react';
import * as Styles from './MobileSettingsPanel.styles';
import { Icon } from 'semantic-ui-react';
import Loading from 'components/common/loading';
import { SettingsTabs } from './components/SettingsAccordion';
import { mobileTabs } from 'constants/index';
import { Head } from 'components/manage-vm/components';

const SettingsPanel = ({
  isLoading,
  isClose,
  data,
  type,
  upgradeProps,
  refreshServer,
  serverActionsLoading,
  initialTab,
  setDeletedIds,
  managerVmRefetch,
  allTags,
  isTags,
  statusOfServer,
  managerVmIsFetching,
  serverSizeLoading,
  serverSizeData,
  initialActionTab,
}) => {
  if (isLoading || serverSizeLoading) {
    return <Loading gap={50} />;
  }

  return (
    <Styles.MobileSettingPanel>
      <Styles.Heading className={`bg-white pb-3 floating`}>
        <div className="pl-4 pt-4 mt-1 ">
          <Head data={data} />
        </div>
        <Styles.CrossIcon className="pt-3 ">
          <Icon size="large" name="close" onClick={() => isClose(false)} />
        </Styles.CrossIcon>
      </Styles.Heading>
      <SettingsTabs
        tabs={mobileTabs(
          data,
          upgradeProps,
          { refreshServer, loading: serverActionsLoading },
          type,
          setDeletedIds,
          managerVmRefetch,
          allTags,
          isTags,
          statusOfServer,
          managerVmIsFetching,
          serverSizeData,
          initialActionTab,
        )}
        update={[data]}
        loading={serverActionsLoading}
        initialTab={initialTab}
      />
    </Styles.MobileSettingPanel>
  );
};

export default React.memo(SettingsPanel);
