import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import Fade from '@material-ui/core/Fade';
import { withStyles } from '@material-ui/core/styles';

const TooltipStyle = withStyles((theme) => ({
  tooltip: {
    color: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    fontSize: 13,
    maxWidth: 195,
  },
}))(Tooltip);

const Tooltips = ({ children, title, placement }) => {
  return (
    <TooltipStyle
      className=""
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 600 }}
      title={title || ''}
      placement={placement}
      arrow
    >
      {children}
    </TooltipStyle>
  );
};

export default Tooltips;
