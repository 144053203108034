import { pStyles } from 'components/reusable-css';
import styled from 'styled-components';
import { variables } from 'variables';

export const DomainStep1SC = styled.div`
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.pageBackgroundColor};
  padding: ${variables.pageContentPadding}px;
  width: 100%;
  border-bottom: 4px solid ${(props) => props.theme.primaryDarkColor};
  padding-bottom: 4em;
   .cart-icon {
    margin-left: 1.5em;
    margin-top: -8px;
    font-size: 20px;
    cursor: pointer;
  }
  .noOfvs {
    background-color: red;
    color: white;
    font-size: 14px;
    border-radius: 50px;
    padding: 1px 6px;
    margin-top: -20px;
    margin-left: -10px;
    font-weight: bold;
    z-index: 1000;
    cursor: pointer;
  }
`;

export const StepDescription = styled.div`
  margin: 1.5em 0 0.5em 0;
  font-size: ${(props) => props.theme.default?.p};
  ${(props) => pStyles(props.theme.resolutions)};
`;

export const DomainContainer = styled.div`
  display: flex;
`;

export const IsDomainAvailable = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5em 1em;
  background: white;
  border-radius: 5px;
  width: 34em;
  border: 2px solid #d8d8d8;
  h4 {
    margin: 0;
    margin-left: 20px;
    color: ${(props) => props.theme.primaryDarkColor};
    font-weight: bold;
  }
`;

export const DomainAvailable = styled.div`
  background-color: green;
  display: flex;
  color: white;
  border-radius: 6px;
  padding: 6px 12px;
  p {
    margin: 0;
    margin-left: 5px;
  }
  i {
    color: white;
    margin-top: -1px;
  }
`;

export const DomainNotAvailable = styled.div`
  background-color: #bd0505;
  display: flex;
  color: white;
  border-radius: 6px;
  padding: 6px 12px;
  p {
    margin: 0;
    margin-left: 5px;
  }
  i {
    color: white;
    margin-top: -1px;
  }
`;
