import React from 'react';
import * as Styles from './DomainStep1.style';
import { Trans, useTranslation } from 'react-i18next';
import { useMediaQuery } from '@material-ui/core';
import Heading from 'components/Heading/Heading';
import { H4, P } from 'components/reusable-css';
import CustomButton from 'components/common/custom-button';
import Input from 'components/input';
import { Icon } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { UpperCaseWord } from 'assets/css/common-styles';
import { navLinks } from 'utils/nav-links-util';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const DomainStep1 = ({ handleNewDomain, isLoading, isDomainAvailable, formikProp }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { values, errors, handleChange, touched, handleBlur } = formikProp;
  const isLaptop = useMediaQuery('(max-width:1366px)');
  const userAccount = useSelector((state) => state.user.account);
  const hanldeNavigation = () => history.replace(navLinks.domain_instance_summary.link);
  const noOfDomain = useSelector((state) => state.domainCart.domain_deploy);

  return (
    <Styles.DomainStep1SC id="ST1">
      <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%' }}>
        <Icon name="cart" onClick={hanldeNavigation} className="cart-icon" />
        <div className="noOfvs" onClick={hanldeNavigation}>
          {noOfDomain.length}
        </div>
      </div>
      <Heading name={`domain/instance/create.step1_string1`}>
        <Styles.StepDescription>{t('domain/instance/create.step1_string2')}</Styles.StepDescription>
        <div className="d-flex flex-column">
          <Styles.DomainContainer>
            <Input
              placeholderClass
              value={values['new_domain']}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors['new_domain']}
              touched={touched['new_domain']}
              styling={{ minWidth: '31.8em' }}
              type="text"
              name={'new_domain'}
              onEnter={() => handleNewDomain(values)}
            />
            <CustomButton
              isLoading={isLoading}
              buttonStyles={{
                marginLeft: '10px',
                height: '48px',
                padding: '0rem 2em',
                marginTop: isLaptop ? '1.8em' : '1.69em',
              }}
              title={t('search')}
              onClick={() => handleNewDomain(values)}
            />
          </Styles.DomainContainer>

          {isDomainAvailable == 'domain_available' ? (
            <Styles.IsDomainAvailable>
              <Styles.DomainAvailable>
                <Icon name="check" />
                <P>{t('domain/instance/create.step1_string3')}</P>
              </Styles.DomainAvailable>
              <H4>
                <Trans
                  i18nKey={'domain/instance/create.step1_string4'}
                  values={{
                    currency: userAccount.account_currency,
                    currency_symbol: userAccount.account_currency_symbol,
                    price: values.price,
                  }}
                  components={{ uppercase: <UpperCaseWord /> }}
                />
              </H4>
            </Styles.IsDomainAvailable>
          ) : isDomainAvailable == 'domain_not_available' ? (
            <Styles.IsDomainAvailable>
              <Styles.DomainNotAvailable>
                <Icon name="ban" />
                <P>{t('domain/instance/create.step1_string5')}</P>
              </Styles.DomainNotAvailable>
            </Styles.IsDomainAvailable>
          ) : null}
        </div>
      </Heading>
    </Styles.DomainStep1SC>
  );
};

export default DomainStep1;
