import styled from 'styled-components';

export const NewDomainSC = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  background: white;
  height: calc(100vh - ${(props) => props.theme.default?.top_bar_height} - ${(props) => props.topWarningHeight + 'px'});
`;
