import { h2Styles, h6Styles } from 'components/reusable-css';
import styled from 'styled-components';

export const DomainLastStepSC = styled.div`
  display: flex;
  border-bottom: 1px solid #ef6c0f;
  border-top: 4px solid ${(props) => props.theme.primaryDarkColor};
  align-items: center;
  justify-content: center;
  color: black;
  font-family: 'Open Sans', verdana, arial, sans-serif !important;
`;

export const PriceServer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f3f1f2;
  padding: 28px 15px;
  justify-content: center;
  align-items: center;
  width: 60%;
  min-height: 205px;
  @media only screen and (max-width: 765px) {
    width: 100%;
    min-height: 150px;
    padding: 0 15px;
    width: 50%;
  }
`;

export const VirtualServer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #dfdbda;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 40%;
  height: 205px;
  @media only screen and (max-width: 765px) {
    width: 100%;
    height: 150px;
    width: 50%;
  }
`;

export const SubmitServer = styled.div`
  display: flex;
  flex-direction: wrap;
`;

export const Content = styled.div`
  text-align: right;
  .your_server_price {
    font-weight: 700;
  }
`;

export const Price = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 5px;
`;

export const PerYear = styled.p`
  font-weight: 700;
  font-size: ${(props) => props.theme.default?.h6};
  ${(props) => h6Styles(props.theme.resolutions)};
  color: #ef6c0f;
`;

export const PriceUnit = styled.span`
  font-size: ${(props) => props.theme.default?.h2};
  ${(props) => h2Styles(props.theme.resolutions)};
`;
