import React, { useMemo, useState } from 'react';
import CartSummary from 'components/doamain/CartSummary';
import PaymentMethod from 'components/doamain/PaymentMethod';
import PlaceOrder from 'components/doamain/PlaceOrder';
import ContactDetail from 'components/doamain/ContactDetail';
import { useSelector } from 'react-redux';

const DomainCheckout = () => {
  const [term, setTerm] = useState(1);
  const [selected, setSelected] = useState(100);
  const handleClick = (index) => setSelected(index);
  const domainCart = useSelector((state) => state.domainCart.domain_deploy);
 
  let title =
    selected == 100 || selected == 1 ? 'domain/instance/summary.string35' : 'domain/instance/summary.string44';
  
    const totalPrice = useMemo(() => {
    return domainCart.reduce((acc, item) => {
      return acc + parseFloat(item.price * item.term);
    }, 0);
  }, [term, domainCart]);

  return (
    <div>
      <CartSummary totalPrice={totalPrice} setTerm={setTerm} />
      <ContactDetail />
      <PaymentMethod handleClick={handleClick} selected={selected} />
      <PlaceOrder title={title} totalPrice={totalPrice} selected={selected} />
    </div>
  );
};

export default DomainCheckout;
