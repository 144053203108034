import React from 'react';
import { useTranslation } from 'react-i18next';
import { CustomButton as Button } from '@sidraaabdullah/test-shared-library';
import { SPAN } from 'components/reusable-css';

const CustomButton = ({ title, buttonStyles, isLoading, disabled, ...remaining }) => {
  const { t } = useTranslation();
  return (
    <Button
      Paragraph={SPAN}
      title={t(title || 'string6')}
      disabled={disabled}
      isLoading={isLoading}
      buttonStyles={buttonStyles}
      {...remaining}
    />
  );
};

export default CustomButton;
