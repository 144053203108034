import React from 'react';
import { useMutation, useQuery } from 'react-query';
import DesktopSettingsPanel from 'components/manage-vm/settings-panel/DesktopSettingsPanel';
import { sizePricingFilter } from 'utils/vm-deployment';
import MobileSettingPanel from './mobile-settings-panel';
import hooks from 'hooks';
import { INSTANCE_ACTION, UPDATE_SERVER_SIZE } from '../queries';
import { get } from 'lodash';
import {
  updateValidationSchema,
  upgradeServerNewPrice,
  updateValidationSchemaWithSlider,
  getUpdatedFeatures,
} from '../utils';
import { CREDIT_CARD } from 'constants/index';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { BM_SIZES } from 'components/bm-deployment/queries';
import ConditionalFragment from 'components/conditional-fragment';
import { useBackupPrice } from 'hooks/backup-price';

const SettingPanel = ({
  data,
  type,
  menuOptions,
  setOpenConfigurationPanel,
  initialTab,
  setDeletedIds,
  toggle,
  managerVmRefetch,
  allTags,
  isTags,
  statusOfServer,
  managerVmIsFetching,
  initialActionTab,
}) => {
  const {
    mutationProps,
    isCard: isCardHandle,
    chargeCardParams,
    saveAndChargeCard,
    chargeSavedCard,
    defaultCard,
    saveAndChargeCardLoad,
    chargeSavedCardLoad,
    isSliderInterface,
    isMobile,
  } = hooks();
  const { backupData } = useBackupPrice();

  const { t } = useTranslation();
  const userAccount = useSelector((state) => state.user.account);
  const handleVmRefetch = useSelector((state) => state.vmDeployment.handleVmRefetch);
  const { mutate: refreshServer, isLoading: serverActionsLoading } = useMutation(INSTANCE_ACTION, {
    ...mutationProps('', { errorHeading: t('error') }),
  });
  const { data: fundsData, isLoading: fundLoading } = useQuery('/prices/vs/prepaid_options', {
    enabled: type === 'vs',
  });
  const { data: sizeData, isLoading: sizeLoading } = useQuery('/vs/sizes', {
    enabled: !isSliderInterface && type === 'vs',
  });
  const { mutate: updateSize, isLoading: updateServerSideLoading } = useMutation(UPDATE_SERVER_SIZE, {
    ...mutationProps('', { isSuccess: false }),
  });
  const { data: sizeDataPrice, isLoading: sizePriceLoading } = useQuery('/prices/vs/sizes', {
    enabled: !isSliderInterface && type === 'vs',
  });
  const serverSizes = sizePricingFilter(sizeData, sizeDataPrice);
  const {
    data: sliderData,
    isLoading: sliderLoading,
    isSuccess: sliderSuccess,
  } = useQuery('/prices/vs/sliders', {
    enabled: isSliderInterface && type === 'vs',
  });
  const { data: sliderSizeData, isLoading: sliderSizeLoading } = useQuery('/vs/slidersizes', {
    enabled: isSliderInterface && type === 'vs',
  });

  // SIZE OR MODEL DETAILS
  const { data: serverSizeData, isLoading: serverSizeLoading } = useQuery(
    ['BM_SIZES', { size: data?.size }],
    BM_SIZES,
    {
      enabled: (data?.size ? true : false) && type === 'bm',
    },
  );
  const validation = Yup.lazy(() => {
    let valid = {};
    // let isCard = true;
    // isCard = isCardHandle(values);
    // if (isCard) {
    //   valid = paymentMethodValidation;
    // }
    // if (get(values, 'payment_method') === CREDIT_CARD && isCard) {
    //   if (!defaultCard) {
    //     valid = { ...valid, ...validationObject };
    //   }
    // }
    if (!isSliderInterface) {
      return updateValidationSchema(valid);
    } else {
      return updateValidationSchemaWithSlider(valid);
    }
  });

  const serverUpdating = async (values, resetForm) => {
    let query = { size: values.size.id, id: data.id };
    if (isSliderInterface) {
      query = {
        id: data.id,
        cpucores: '' + values.sliders.cpu,
        ram: String(values.sliders.ram),
        disk: '' + values.sliders.disk,
        nic: values.nic_mbps,
      };
    }
    await updateSize(
      query,
      mutationProps(false, {
        isSuccess: true,
        onSuccess: () => {
          resetForm();
          handleVmRefetch();
        },
        t,
      }),
    );
  };
  const sizeInterfaceUpgrade = async (values, { resetForm }) => {
    const features = getUpdatedFeatures(data, {
      isUpgraded: true,
      backupData: backupData(data),
      formikProp: { values },
    });
    const price = upgradeServerNewPrice(values, features);
    if (get(values, 'payment_method') === CREDIT_CARD && isCardHandle(values)) {
      if (!defaultCard) {
        await saveAndChargeCard(chargeCardParams(values, +price.mo), {
          onSuccess: async () => await serverUpdating(values, resetForm),
        });
      } else {
        await chargeSavedCard(
          {
            amount: +price.mo,
            currency: userAccount.account_currency,
          },
          {
            onSuccess: async () => await serverUpdating(values, resetForm),
          },
        );
      }
    } else {
      await serverUpdating(values, resetForm);
    }
  };
  const sliderInterfaceUpgrade = async (values, { resetForm }) => {
    // if (get(values, 'options_prepaid.id') == 0) {
    await serverUpdating(values, resetForm);
    // }
  };
  const handleServerUpgrade = async (values, { resetForm }) => {
    if (!isSliderInterface) {
      await sizeInterfaceUpgrade(values, { resetForm });
    } else {
      await sliderInterfaceUpgrade(values, { resetForm });
    }
  };
  const handleRefetch = async (action, extra = {}) =>
    await refreshServer(
      { id: data.id, type, action, ...extra },
      {
        onSuccess: () => {
          if (managerVmRefetch) managerVmRefetch();
        },
      },
    );
  return (
    <React.Fragment>
      <ConditionalFragment condition={!isMobile}>
        <DesktopSettingsPanel
          serverSizeData={get(serverSizeData, 'bm_sizes[0]', {})}
          serverSizeLoading={serverSizeLoading}
          managerVmRefetch={managerVmRefetch}
          allTags={allTags}
          isTags={isTags}
          statusOfServer={statusOfServer}
          managerVmIsFetching={managerVmIsFetching}
          sizeData={serverSizes}
          sizeLoading={sizeLoading}
          fundLoading={fundLoading}
          sizePriceLoading={sizePriceLoading}
          fundsData={fundsData}
          data={data}
          isClose={setOpenConfigurationPanel}
          isLoading={sizeLoading || fundLoading || sizePriceLoading || sliderSizeLoading || sliderLoading}
          sliderData={sliderData}
          sliderSuccess={sliderSuccess}
          sliderSizeData={sliderSizeData}
          type={type}
          menuOptions={menuOptions}
          upgradeProps={{
            chargeSavedCardLoad,
            handleServerUpgrade,
            validation,
            updateServerSideLoading,
            saveAndChargeCardLoad,
          }}
          serverActionsLoading={serverActionsLoading}
          refreshServer={handleRefetch}
          initialTab={initialTab}
          setDeletedIds={() => {
            setDeletedIds();
            toggle();
          }}
          toggle={toggle}
          initialActionTab={initialActionTab}
        />
      </ConditionalFragment>
      <ConditionalFragment condition={isMobile}>
        <MobileSettingPanel
          serverSizeData={get(serverSizeData, 'bm_sizes[0]', {})}
          serverSizeLoading={serverSizeLoading}
          sizeData={serverSizes}
          sizeLoading={sizeLoading}
          fundLoading={fundLoading}
          sizePriceLoading={sizePriceLoading}
          data={data}
          isClose={setOpenConfigurationPanel}
          isLoading={sizeLoading || fundLoading || sizePriceLoading || sliderSizeLoading || sliderLoading}
          type={type}
          upgradeProps={{
            chargeSavedCardLoad,
            handleServerUpgrade,
            validation,
            updateServerSideLoading,
            saveAndChargeCardLoad,
            fundsData,
            sizeData: serverSizes,
            sliderData,
            sliderSuccess,
            sliderSizeData,
          }}
          menuOptions={menuOptions}
          serverActionsLoading={serverActionsLoading}
          refreshServer={handleRefetch}
          initialTab={initialTab}
          setDeletedIds={() => {
            setDeletedIds();
            toggle();
          }}
          toggle={toggle}
          managerVmRefetch={managerVmRefetch}
          allTags={allTags}
          isTags={isTags}
          statusOfServer={statusOfServer}
          managerVmIsFetching={managerVmIsFetching}
          initialActionTab={initialActionTab}
        />
      </ConditionalFragment>
    </React.Fragment>
  );
};

export default React.memo(SettingPanel);
