/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-02-02 23:27:25
 * @modify date 2021-02-02 23:27:25
 */

import React from 'react';
import { LoadingSC } from './Loading.style';
import { ReactComponent as LoadingImage } from 'assets/images/loading.svg';

export default function Loading({ gap = 10, className, background }) {
  return (
    <LoadingSC data-testid="loading" gap={gap} className={className} background={background}>
      <LoadingImage className="loading-img" />
    </LoadingSC>
  );
}
