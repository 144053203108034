export const getLocalStorageValues = () => {
  let UserName = localStorage.getItem('userName');
  let lang = localStorage.getItem('i18nextLng');
  let vs_cart = localStorage.getItem('vs_cart');
  let cart_selected_region = localStorage.getItem('cart_selected_region');
  let remember_cart_login = localStorage.getItem('remember_cart_login');
  let promoCode = localStorage.getItem('promoCode');
  let domain_cart = localStorage.getItem('domain_cart');

  return {
    userName: JSON.parse(UserName) || {},
    lang,
    cart_selected_region: JSON.parse(cart_selected_region) || {
      city: 'ashburn',
      country: 'united states',
      id: 'ash1-1',
      new_deployments_allowed: '1',
      region: 'east coast',
      state: 'virginia',
    },
    vs_cart: JSON.parse(vs_cart) || [],
    remember_cart_login: JSON.parse(remember_cart_login) || {},
    promoCode: `/${promoCode}` || '',
    domain_cart: JSON.parse(domain_cart) || [],
  };
};
