import React from 'react';
import AddNewRole from './AddNewRole';
import AddNewUser from './AddNewUser';
import * as Styles from './UserManagement.style';
import Comment from 'components/comment';
import { GET_ROLES, GET_USERS, LABELS } from 'components/user-management/queries';
import { useQuery } from 'react-query';
import Loading from 'components/common/loading';
import SpiderIcon from 'assets/svg/spider-solid.svg';
import { P } from 'components/reusable-css';
import { Trans } from 'react-i18next';

const UserManagement = () => {
  const { data: getRoles, refetch: roleRefetch, isLoading: getRoleLoading, isError: getRolesError } = useQuery(
    'GET_ROLES',
    GET_ROLES,
  );
  const { data: getUsers, refetch: usersRefetch, isLoading: getUsersLoading } = useQuery('GET_USERS', GET_USERS);
  const { data: labels, isLoading: labelLoading } = useQuery('LABELS', LABELS);

  if (labelLoading || getRoleLoading || getUsersLoading) {
    return <Loading gap={200} />;
  }
  return (
    <Styles.UserManagementSC>
      <AddNewRole
        roleRefetch={roleRefetch}
        getRoleLoading={getRoleLoading}
        getRoles={getRoles}
        getRolesError={getRolesError}
        labels={labels}
        usersRefetch={usersRefetch}
      />
      <AddNewUser
        getRoles={!getRolesError ? getRoles : []}
        getUsers={getUsers}
        roleRefetch={roleRefetch}
        usersRefetch={usersRefetch}
      />
      <div className="px-3 py-4">
        <Comment>
          <P>
            <Trans i18nKey={'account/usermgmt.string1'}>
              <img src={SpiderIcon} width="20" height="20" />
            </Trans>
          </P>
        </Comment>
      </div>
    </Styles.UserManagementSC>
  );
};

export default UserManagement;
