import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import moment from 'moment';
import * as Styles from './Header.style';
import { navLinks, socialMediaLinks } from 'utils/nav-links-util';
import { useDispatch, useSelector } from 'react-redux';
import currencyFilter from 'utils/currency-filter';
import VmUtils from 'pages/member/new-vm/util';
import hooks from 'hooks';
import { userRights } from 'store/selectors/user-details';
import { useMutation } from 'react-query';
import { UPDATE_PREFERENCES } from 'components/manage-vm/queries';
import { logoutUser } from 'store/actions/user-details';
import { CustomHeader } from '@sidraaabdullah/test-shared-library';
import { H6 } from 'components/reusable-css';
import { languageArray } from 'constants/signUp';
import { Icon } from 'semantic-ui-react';

export default function Header({ isAppDrawerOpen, username, onAppDrawerToggle, queryClient, profileMenu }) {
  const dispatch = useDispatch();
  const { logout } = hooks();
  const rights = useSelector(userRights);
  const { t, i18n } = useTranslation();
  let defaultLang = i18n.language == 'en-US' ? 'en' : i18n.language;
  const [language, setLanguage] = useState(defaultLang);
  const { mutate: updatePreferences } = useMutation(UPDATE_PREFERENCES);
  const userAccount = useSelector((state) => state.user.account);
  const userUi = useSelector((state) => state.user.ui);
  const { newVmPath } = VmUtils();
  const { account_bucks = 0, account_currency = 0, account_funds = 0, account_approved } = userAccount || {};
  const handleNavigate = async (link) => {
    if (link.id === 'logout') {
      dispatch(logoutUser(true));
      try {
        await logout(queryClient);
        dispatch(logoutUser(false));
      } catch (error) {
        dispatch(logoutUser(false));
        console.log('error::', error);
      }
    }
  };

  const handleLanguage = async (value) => {
    await updatePreferences({ language: value });
    setLanguage(value);
    i18n.changeLanguage(value);
  };
  const handleNavigateAccount = async (link) => {
    if (link.url === '/logout') {
      await logout(queryClient);
    }
  };

  return (
    <CustomHeader
      isAppDrawerOpen={isAppDrawerOpen}
      onAppDrawerToggle={onAppDrawerToggle}
      profileMenu={profileMenu}
      handleLogout={handleNavigate}
      account_approved={account_approved}
      handleLanguage={handleLanguage}
      language={language}
      rights={rights}
      userUi={userUi}
      string2={t('string2')}
      header={{
        string1: (
          <Trans
            i18nKey={'header.string1'}
            components={{
              changelog_link: <Styles.ChangelogLink to={navLinks.changelog.link} target="_blank" />,
              text: <Styles.Text />,
            }}
            values={{
              version: userUi?.portal_version,
              username: username,
              release_date: moment(userUi?.portal_release_date).format('MMMM DD, YYYY'),
            }}
          />
        ),
        string2: (
          <Trans
            i18nKey={'header.string2'}
            components={{
              changelog_link: <Styles.ChangelogLink to={navLinks.changelog.link} target="_blank" />,
              text: <Styles.Text />,
            }}
            values={{
              version: userUi?.portal_version,
              username: username,
              release_date: moment(userUi?.portal_release_date).format('MMMM DD, YYYY'),
            }}
          />
        ),
        string3: (
          <Trans
            i18nKey={'header.string3'}
            values={{
              account_price: currencyFilter(account_funds, account_currency),
              buck_price: currencyFilter(account_bucks, account_currency, true),
            }}
          />
        ),
        string4: (
          <Trans i18nKey={'header.string4'}>
            <a href={'#' + newVmPath}>{t('virtual server')}</a>
            <a href={'#' + navLinks.new_server.link}>{t('bare metal server')}</a>
          </Trans>
        ),
        string5: t('header.string5'),
        string6: t('header.string6'),
        string7: t('header.string7'),
        string8: t('header.string8'),
      }}
      Heading6={H6}
      socialMediaLinks={socialMediaLinks(userUi)}
      navLinks={navLinks}
      languageArray={languageArray}
      AccountLabel={Styles.AccountLabel}
      TriggerContainer={Styles.TriggerContainer}
      HeaderSC={Styles.HeaderSC}
      LeftHeader={Styles.LeftHeader}
      Para={Styles.Para}
      RightHeader={Styles.RightHeader}
      SocialMediaLink={Styles.SocialMediaLink}
      drawerList={
        <div>
          {profileMenu?.accountmenu?.map((link, i) =>
            link.external_link === '1' ? (
              <Styles.AchorTag key={i} href={link.url} target="blank">
                <Icon name={link.menu_icon} />
                <div>{t(link.api_language_string_title)}</div>
              </Styles.AchorTag>
            ) : (
              <Styles.LinkSC onClick={() => handleNavigateAccount(link)} key={link.id} href={`#${link.url}`}>
                <Icon name={link.menu_icon} />
                <div>{t(link.api_language_string_title)}</div>
              </Styles.LinkSC>
            ),
          )}
        </div>
      }
    />
  );
}
