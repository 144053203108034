import React, { useState } from 'react';
import Account from './account/Account';
import { AddFundSC } from './AddFund.style';
import ServicesByServerpoint from './services-by-severpoint';
import Invoices from './invoice/Invoices';
import { useQuery } from 'react-query';
import Loading from 'components/common/loading/Loading';
import { useSelector } from 'react-redux';

const AddFund = () => {
  const [modalActive, setModalActive] = useState(false);
  const { data: storedCreditCards, refetch: refetchStoredCreditCards } = useQuery('/billing/ccs');
  const userUI = useSelector((state) => state.user.ui);
  const { data: invoiceData, isLoading } = useQuery('/billing/invoice', {
    enabled: userUI.show_invoice_list == 1,
    refetchInterval: 60 * 1000, //one minute
  });
  if (isLoading) {
    return <Loading gap={50} />;
  }

  const handleShowModal = () => {
    setModalActive(true);
  };
  const handleCloseModal = () => {
    setModalActive(false);
    document.getElementById('add_funds_vs_reserved').classList.remove('focused-payment');
  };

  return (
    <AddFundSC>
      {modalActive && <div onClick={() => handleCloseModal()} className="overlay" />}

      <Account storedCreditCards={storedCreditCards} refetchStoredCreditCards={refetchStoredCreditCards} />
      <ServicesByServerpoint
        refetchStoredCreditCards={refetchStoredCreditCards}
        modalActive={modalActive}
        handleCloseModal={handleCloseModal}
      />
      {userUI?.show_invoice_list == 1 && <Invoices invoiceData={invoiceData} onShowModal={handleShowModal} />}
    </AddFundSC>
  );
};
export default AddFund;
