import React from 'react';
import * as Styles from 'pages/member/domain-checkout/DomainCheckout.style';
import { P } from 'components/reusable-css';
import { Trans, useTranslation } from 'react-i18next';
import Heading from 'components/Heading/Heading';
import { isSafari } from 'react-device-detect';
import PaymentForm from 'components/Payment-form';
import { Formik } from 'formik';
import { initialValues, NewCreditCardError } from 'pages/member/add-fund/constants';
import CustomButton from 'components/common/custom-button/custom-button';
import { scrollToSection, vm_year } from 'utils';
import { Message } from 'components/alert/Message';
import { useMutation } from 'react-query';
import { SAVE_CHARGE_CARD } from 'components/vm-deployment/queries/billing';
import { useSelector } from 'react-redux';
import { validationObject } from 'validations';
import { CustomHook } from 'utils/custom-hook';
import classes from 'assets/css/payment-form.module.css';

const buttons = [
  { id: 0, heading: 'string37', text: 'string28' },
  { id: 1, heading: 'string38', text: 'string29' },
  { id: 2, heading: 'string39', text: 'string30' },
  { id: 3, heading: 'string40', text: 'string31' },
  { id: 4, heading: 'string41', text: 'string32' },
  { id: 5, heading: 'string42', text: 'string33' },
];

const PaymentMethod = ({ selected, handleClick }) => {
  const { t } = useTranslation();
  const { mutationProps } = CustomHook();
  const { mutate: createCreditCard, isLoading: createCreditCardLoading } = useMutation(SAVE_CHARGE_CARD);
  const credit_card_info_currency = useSelector((state) => state?.user?.account);
  const state = useSelector((state) => state.user);
  const credit_card_info = useSelector((state) => state.user.billing_info);

  const handleCreateCreditCard = async (values, resetForm) => {
    try {
      await createCreditCard(
        {
          number: values.card_number,
          expmonth: ('0' + values.card_month).slice(-2),
          expyear: vm_year(values.card_year),
          cvv: values.cvv,
          name: values.card_name,
          phone: values.phone.replace(/\D/g, ''),
          organization: values.company,
          address1: values.billing_address,
          address2: values.address_continue,
          city: values.city,
          state: values.state,
          zipcode: values.zip,
          country: values.country,
          bank: values.bank_name,
          bankphone: values.bank_phone_number.replace(/\D/g, ''),
          currency: credit_card_info_currency?.account_currency,
          amount_to_charge: '0',
          make_default: '1',
        },
        mutationProps(resetForm, {
          onSuccess: async () => {
            state.userAccountRefetch();
            state.userBillingAccountRefetch();
          },
        }),
      );
    } catch (err) {
      Message.error(t(`${err.response.data?.error}`));
    }
  };

  return (
    <Styles.PaymentMethodContainer>
      <Heading name="domain/instance/summary.string27">
        <Styles.PaymentMethodButton id="credit_cards">
          {buttons.map((data) => (
            <Styles.PaymentMethodSingleButton
              greyOut={data.id == 3 && !isSafari}
              selected={data.id == selected}
              key={data.id}
              onClick={() => data.id != 3 && !isSafari && handleClick(data.id)}
            >
              <P>{t(`domain/instance/summary.${data.text}`)}</P>
            </Styles.PaymentMethodSingleButton>
          ))}
        </Styles.PaymentMethodButton>
        {selected != 100 && selected != 1 && <P>{t(`domain/instance/summary.${buttons[selected]?.heading}`)}</P>}
        {selected == 1 && (
          <Formik
            initialValues={initialValues}
            validationSchema={validationObject}
            onSubmit={(values) => handleCreateCreditCard(values)}
          >
            {(formikProp) => (
              <div style={{ paddingTop: '1em' }}>
                {credit_card_info?.billing_default_credit_card_exp &&
                credit_card_info?.billing_default_credit_card_last_four ? (
                  <div className={classes.default_credit}>
                    <div className={classes.heading}>
                      <Styles.Heading>{t('common/credit_card_module.string6')}:</Styles.Heading>
                    </div>
                    <P className={classes.exp}>
                      <Trans
                        i18nKey="common/credit_card_module.string7"
                        values={{
                          credit_card_info: credit_card_info?.billing_default_credit_card_last_four,
                          credit_card_info_expiry: credit_card_info?.billing_default_credit_card_exp,
                        }}
                      />
                    </P>
                  </div>
                ) : (
                  <>
                    <PaymentForm addFunds formikProp={formikProp} />
                    <CustomButton
                      isLoading={createCreditCardLoading}
                      onClick={async () => {
                        const errors = await formikProp?.validateForm();
                        formikProp?.handleSubmit();
                        if (NewCreditCardError(errors)) {
                          Message.error(t('string14'), t('string13'));
                          return scrollToSection('credit_cards');
                        }
                      }}
                      title={t('account/billing_info.string32')}
                      buttonStyles={{
                        marginTop: '2rem',
                        opacity: !formikProp.isValid && 0.3,
                        backgroundColor: !formikProp.isValid && 'gray',
                      }}
                    />
                  </>
                )}
              </div>
            )}
          </Formik>
        )}
      </Heading>
    </Styles.PaymentMethodContainer>
  );
};

export default PaymentMethod;
