import React from 'react';
import { useTranslation } from 'react-i18next';
import { changelogs_en, changelogs_es } from 'constants/index';
import { H5, H6, P } from 'components/reusable-css';
import { Changelog as ChangelogComponent } from '@sidraaabdullah/test-shared-library';

const Changelog = () => {
  const translations = {
    en: changelogs_en,
    es: changelogs_es,
  };
  const { i18n } = useTranslation();

  return <ChangelogComponent Heading5={H5} Heading6={H6} translationsArr={translations[i18n.language]} Paragraph={P} />;
};

export default Changelog;
