import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Icon } from 'semantic-ui-react';
import * as Styles from './FundCard.style';
import { useSelector } from 'react-redux';
import { fundCards } from '../util';

export default function FundCard({ data, selected = false, onClick, fundsPrice, customPrice }) {
  const { t } = useTranslation();
  const userAccount = useSelector((state) => state.user.account);
  const price = customPrice || fundsPrice || useSelector((state) => state.vmDeployment.pricingDetails);
  const noOfVM = useSelector((state) => state.vmDeployment.noOfVM);
  return (
    <Styles.ServerSizeCardSC data-testid="fund-card" selected={selected} onClick={() => onClick(data)}>
      <Styles.Tag showTag={data.price_times_months == 12}>
        {data.price_times_months == 12 ? t('vs/instance/create.step7_string7') : null}
      </Styles.Tag>

      {fundCards(data, price, noOfVM, customPrice).map((item, key) => (
        <div key={key}>
          <Styles.NameContainer>
            <Styles.Name>{t(item.title)}</Styles.Name>
          </Styles.NameContainer>
          <Styles.Price>
            <Styles.PerMonth>
              {item.bonusPrice ? (
                <Styles.PerMonth>
                  <Trans
                    i18nKey="vs/instance/create.step7_string23"
                    values={{
                      currency_symbol: userAccount.account_currency_symbol,
                      bounus_price: item.bonusPrice,
                      price: item.price,
                    }}
                    components={{
                      price: <Styles.PriceUnit />,
                      add: <Styles.Add />,
                    }}
                  >
                    <p>{item.bonusPrice} !bouns</p>
                  </Trans>
                </Styles.PerMonth>
              ) : (
                <Styles.PerMonth>
                  <Trans
                    i18nKey="vs/instance/create.step7_string24"
                    values={{ currency_symbol: userAccount.account_currency_symbol, price: item.price }}
                    components={{
                      price: <Styles.PriceUnit />,
                    }}
                  />
                </Styles.PerMonth>
              )}
            </Styles.PerMonth>
          </Styles.Price>
          <Styles.Features>
            {item.options.map((text, key) => (
              <Styles.Feature key={key}>
                <Icon name="check" />
                {t(text)}
              </Styles.Feature>
            ))}
          </Styles.Features>
        </div>
      ))}

      {selected && (
        <Styles.SelectedTag>
          <Icon name="check" />
          {t('vs/instance/create.step7_string8')}
        </Styles.SelectedTag>
      )}
    </Styles.ServerSizeCardSC>
  );
}
