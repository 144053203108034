import React from 'react';
import { NavLink } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';
import * as Styles from './AppDrawer.style';
import Badge from 'components/common/badge/Badge';
import LogoImage from 'assets/images/logo-large.svg';
import { useSelector, useDispatch } from 'react-redux';
import { get } from 'lodash';
import { toggleAppDrawer } from 'store/actions/common-actions';
import { getMenuIcon } from 'constants/menu-icon';
import { useTranslation } from 'react-i18next';
import { CustomAppDrawer } from '@sidraaabdullah/test-shared-library';

export default function AppDrawer({
  isAppDrawerOpen,
  activeSectionIndex,
  activeMobileSectionIndex,
  onSectionClick,
  onMobileSectionClick,
  menuData,
}) {
  const { t } = useTranslation();
  const ui = useSelector((state) => state.user.ui);
  const is_creation_in_process = useSelector((state) => state.vmDeployment.is_creation_in_process);
  const dispatch = useDispatch();

  return (
    <CustomAppDrawer
      isAppDrawerOpen={isAppDrawerOpen}
      AppDrawerSC={Styles.AppDrawerSC}
      AppDrawerHeader={
        <Styles.AppDrawerHeader href={ui?.homepage} target="_blank">
          <Styles.LogoImageSC src={LogoImage} alt="logo" />
        </Styles.AppDrawerHeader>
      }
      SectionLinksContainer={
        <Styles.SectionLinksContainer>
          {menuData?.menu?.map((link, index) => {
            let menuIconName =
              activeSectionIndex === index ? link.menu_icon.split('.')[0] + '_hover.svg' : link.menu_icon;
            return (
              <Styles.SectionLink
                key={link.id}
                onClick={() => onSectionClick(index)}
                to={`${link.default_url}`}
                active={activeSectionIndex === index}
              >
                <Styles.SectionIcon src={getMenuIcon(menuIconName)} />
                <Styles.SectionLabel>{t(link.api_language_string_title)}</Styles.SectionLabel>
              </Styles.SectionLink>
            );
          })}
        </Styles.SectionLinksContainer>
      }
      MainLinksContainer={
        <Styles.MainLinksContainer>
          {get(menuData?.menu, `${activeSectionIndex}.submenu`, []).map((link) => {
            return (
              <>
                {link.hr == 1 && <Styles.Divider key={link} />}
                <Styles.BlinkBackground
                  key={link.id}
                  isAnimation={link.url == '/vs/instance' && is_creation_in_process}
                >
                  <NavLink key={link.id} to={link.url} className="main-link">
                    <Styles.MainLinkIcon />
                    <Styles.MainLinkLabel>{t(link.api_language_string_title)}</Styles.MainLinkLabel>
                    {link.count != 'null' ? <Badge number={link.count} /> : null}
                  </NavLink>
                </Styles.BlinkBackground>
              </>
            );
          })}
        </Styles.MainLinksContainer>
      }
      AppDrawerMobileContainer={menuData?.menu?.map((sectionLink, index) => (
        <Styles.MobileSectionLinkContainer
          key={sectionLink.id}
          active={index === activeMobileSectionIndex}
          linkCounts={sectionLink.submenu.length}
        >
          <Styles.MobileSectionLink
            active={index === activeMobileSectionIndex}
            onClick={() => onMobileSectionClick(index)}
          >
            {t(sectionLink.api_language_string_title)}
            <Icon name="dropdown" />
          </Styles.MobileSectionLink>
          <Styles.MobileLinksContainer>
            {sectionLink.submenu?.map((link) => (
              <NavLink key={link.id} to={link.url} className="main-link" onClick={() => dispatch(toggleAppDrawer())}>
                <Styles.MainLinkLabel>{t(link.api_language_string_title)}</Styles.MainLinkLabel>
                {link.count != 'null' ? <Badge number={link.count} /> : null}
              </NavLink>
            ))}
          </Styles.MobileLinksContainer>
        </Styles.MobileSectionLinkContainer>
      ))}
    />
  );
}
