import React, { useEffect } from 'react';
import WHStep2Conatiner from './wh-step-2/WHStep2.conatiner';
import WHStep1Container from './wh-step-1/WHStep1.conatiner';
import * as Styles from './WH.style';
import WHStep3Container from './wh-step-3/WHStep3.container';
import WHStep4Container from './wh-step-4/WHStep4.container';
import WHContainer from './wh-container/WHContainer.container';
import WHStep5Container from './wh-step-5/WHStep5.container';
import WHLastStepConatiner from './wh-last-step/WHLastStep.container';
import { get } from 'lodash';

const WH = ({
  isLoading,
  formikProp,
  whAppData,
  whSizesDataDescription,
  domainLookUploading,
  whRegionData,
  whSizesData,
  whSizesPricesData,
  domainExtData,
  domainLookUp,
}) => {
  const { setFieldValue } = formikProp;

  useEffect(async () => {
    const mostPopularSizeData = await get(whSizesData, 'wh_sizes', []).find((size) => size?.mostpopular == 1);
    const price = await get(whSizesPricesData, 'prices_wh_sizes', []).find((val) => mostPopularSizeData?.id == val?.id);
    setFieldValue('size', mostPopularSizeData);
    setFieldValue('prices.yearly.size', +price?.price_yearly_usd);
    setFieldValue('prices.size', +price?.price_monthly_usd);
  }, [whSizesPricesData, whSizesData]);

  return (
    <Styles.WHSC>
      <WHStep1Container
        formikProp={formikProp}
        domainExtData={domainExtData}
        domainLookUp={domainLookUp}
        domainLookUploading={domainLookUploading}
      />
      <WHStep2Conatiner
        formikProp={formikProp}
        whSizesData={whSizesData}
        whSizesDataDescription={whSizesDataDescription}
        whSizesPricesData={whSizesPricesData}
      />
      <WHStep3Container formikProp={formikProp} whAppData={whAppData} />
      <WHContainer />
      <WHStep4Container formikProp={formikProp} whRegionData={whRegionData} />
      <WHStep5Container formikProp={formikProp} />
      <WHLastStepConatiner isLoading={isLoading} formikProp={formikProp} />
    </Styles.WHSC>
  );
};
export default WH;
