import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { LOG_IN } from 'components/vm-deployment/queries';
import { reactQueryConfig } from 'constants/react-query-config';
import { useMutation, useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import { getLocalStorageValues } from 'constants/local-storage';
import { navLinks } from 'utils/nav-links-util';
import axios from 'axios';
import queryString from 'query-string';
import Loading from 'components/common/loading/Loading';
import { CustomHook } from 'utils/custom-hook';
import { useDispatch, useSelector } from 'react-redux';
import { UPDATE_PREFERENCES } from 'components/manage-vm/queries';
import { languageArray } from 'constants/signUp';
import { SignIn as SignInComponent } from '@sidraaabdullah/test-shared-library';
import { H4, H6, P } from 'components/reusable-css';
import { logoutUser } from 'store/actions/user-details';

const SignIn = ({ queryClient }) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  let { userName: UserName } = getLocalStorageValues();
  let history = useHistory();
  const { logout } = CustomHook();
  let params = queryString.parse(history?.location?.search);
  const obj = localStorage.getItem('remember');
  let email = '';
  let staffTest = params.user && params.onetimepassword;
  const userUi = useSelector((state) => state.user.ui);

  if (obj) {
    const { email: e } = JSON.parse(obj);
    email = e;
  }
  let defaultLang = i18n.language == 'en-US' ? 'en' : i18n.language;
  const [language, setLanguage] = useState(defaultLang);
  const [userName, setUserName] = useState(email);
  const [password, setPassword] = useState('');
  const [trigger, setTrigger] = useState(false);
  const [open, setOpen] = useState(false);
  const [loginResult, setLoginResult] = useState('Thank you! Please go ahead and log in below.');
  const [severity, setSeverity] = useState('');
  const [isRememberPassword, setIsRememberPassword] = useState(email && true);
  const { mutate: updatePreferences, isLoading: updatePreferencesLoading } = useMutation(UPDATE_PREFERENCES);
  const { isLoading: loginIsLoading } = useQuery(
    [
      'LOG_IN',
      { userName: staffTest ? params.user : userName, password: btoa(staffTest ? params.onetimepassword : password) },
    ],
    LOG_IN,
    {
      enabled: trigger,
      ...reactQueryConfig,
      onSuccess: async (res) => {
        dispatch(logoutUser(false));
        await updatePreferences({ language });
        i18n.changeLanguage(language);
        localStorage.setItem('language', language);
        if (res.status == 'pass_reset_required') {
          history.replace(navLinks['expired-password'].link);
        } else if (res.status == 'two_factor_sms_sent') {
          if (isRememberPassword) {
            localStorage.setItem(
              'remember',
              JSON.stringify({
                email: userName,
              }),
            );
          } else {
            localStorage.removeItem('remember');
          }

          history.push({
            pathname: '/pin',
            state: {
              username: staffTest ? params.user : userName,
              password: btoa(staffTest ? params.onetimepassword : password),
              backup_number_available: res?.backup_number_available,
            },
          });
        } else {
          const query = `?sc_username=${res.sc_username}&sc_password=${res.sc_password}`;
          if (res.redirect_type === 'tab') {
            return window.open(`${res.redirect_url}${query}`);
          }
          if (res.redirect_type === 'redirect') {
            await logout('', false);
            return window.location.replace(`${res.redirect_url}${query}`);
          }
          if (queryClient) queryClient.clear();
          axios.defaults.headers.common.Authorization = `Bearer ${res.token}`;
          localStorage.setItem(
            'userName',
            JSON.stringify({
              userName: res.username,
              email: userName,
              token: res.token,
            }),
          );
          if (isRememberPassword) {
            localStorage.setItem(
              'remember',
              JSON.stringify({
                email: userName,
              }),
            );
          } else {
            localStorage.removeItem('remember');
          }

          setTrigger(false);
          history.replace(navLinks.virtual_machines_home.link);
        }
      },
      onError: () => {
        setSeverity('error');
        setLoginResult('Login Failed!');
        setOpen(true);
        setTrigger(false);
        staffTest ? history.replace('/') : null;
      },
    },
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    setTrigger(true);
  };

  useEffect(() => {
    if (staffTest) {
      setTrigger(true);
    }
  }, []);

  if (staffTest) return <Loading gap={50} background="transparent" />;
  if (!staffTest && UserName?.token) {
    return <Redirect from="*" to={userUi?.default_url || '/dashboard'} />;
  }

  const handleLanguage = (value) => {
    setLanguage(value);
    i18n.changeLanguage(value);
  };

  return (
    <SignInComponent
      loginResult={loginResult}
      severity={severity}
      handleSubmit={handleSubmit}
      isLoginLoading={loginIsLoading || trigger || updatePreferencesLoading}
      isAlertOpen={params?.signup_approve || open}
      handleAlert={(e) => setOpen(e)}
      userName={userName}
      handleUserName={(e) => setUserName(e)}
      password={password}
      handlePassword={(e) => setPassword(e)}
      isRememberPassword={isRememberPassword}
      setIsRememberPassword={(e) => setIsRememberPassword(e)}
      language={language}
      handleLanguage={(value) => handleLanguage(value)}
      is_admin={false}
      Heading4={H4}
      Paragraph={P}
      Heading6={H6}
      languageArray={languageArray}
      signin={{
        headerText: t('signin.string1'),
        inputText1: t('signin.string2'),
        inputText2: t('signin.string3'),
        checkboxText: t('signin.string4'),
        forgotPasswordText: t('signin.string5'),
        signUpText: t('signin.string7'),
        buttonText: t('signin.string6'),
      }}
    />
  );
};

export default SignIn;
