/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/jsx-filename-extension */
import { SettingsTabs } from 'components/manage-vm/settings-panel/mobile-settings-panel/components/SettingsAccordion';
import { DestroyVM, NewPassword, Reinstall } from 'components/manage-vm/settings-panel/components/settings-action';
import { Info } from 'components/manage-vm/settings-panel/components';
import { Formik } from 'formik';
import Upgrade from 'components/manage-vm/settings-panel/upgrade';
import ReverseDNS from 'components/manage-vm/settings-panel/reverse-dns';
import Templates from 'components/manage-vm/settings-panel/templates';
import { bmActionsTabs, upgradeInitialValues, templateInitialValues } from 'components/manage-vm/utils';
import { TabContent } from 'components/manage-vm/settings-panel/components/TabContent';
import { useSelector } from 'react-redux';
import { userRights } from 'store/selectors/user-details';
import PaymentTerms from 'components/manage-vm/settings-panel/payment-terms';
import CancelVM from 'components/manage-vm/settings-panel/components/settings-action/CancelVM';

export const managerVmSortBy = [
  {
    value: 'label',
    lang: 'string13',
  },
  {
    value: 'windows',
    lang: 'string14',
  },
  {
    value: 'linux',
    lang: 'string15',
  },
  {
    value: 'status',
    lang: 'string8',
  },
  {
    value: 'primary_ip',
    lang: 'string17',
  },
  {
    value: 'backup_recent',
    lang: 'string18',
  },
  {
    value: 'backup_oldest',
    lang: 'string19',
  },
];

export const managerBmSortBy = [
  {
    value: 'label',
    lang: 'string13',
  },
  {
    value: 'windows',
    lang: 'string14',
  },
  {
    value: 'linux',
    lang: 'string15',
  },
  {
    value: 'primary_ip',
    lang: 'string17',
  },
];

export const settingTabs = {
  upgrade: 'upgrade',
  actions: 'actions',
  'info/IPs': 'info/IPs',
  reverse_DNS: 'reverse_DNS',
  'templates/backups': 'templates/backups',
  bandwidth: 'bandwidth',
  payment_terms: 'payment_terms',
};
export const menuOptions = (rights, data) => {
  const dropdownItems = [
    {
      id: settingTabs.upgrade,
      name: 'Upgrade',
      name_lang: 'vs/instance.string56',
      show: rights.vschange && data?.billing_schedule == 'hourly',
    },
    {
      id: settingTabs.actions,
      name: 'Actions',
      name_lang: 'vs/instance.string57',
      show: rights.vsdelete || rights.vsresetpassword || rights.vsreinstall,
    },
    { id: settingTabs['info/IPs'], name: 'Info / IPs ', name_lang: 'vs/instance.string58' },
    { id: settingTabs.reverse_DNS, name: 'Reverse DNS', name_lang: 'vs/instance.string59' },
    { id: settingTabs['templates/backups'], name: 'Templates / Backups', name_lang: 'vs/instance.string60' },
    {
      show: data?.billing_schedule != 'hourly',
      id: settingTabs['payment_terms'],
      name: 'Payment terms',
      name_lang: 'vs/instance.string179',
    },
  ];
  return dropdownItems.filter((item) => (typeof item.show === 'undefined' ? true : item.show));
};

export const menuOptionsBm = (rights) => {
  const dropdownItems = [
    { id: settingTabs['info/IPs'], name: 'Information / IPs ', name_lang: 'bm/instance.string15' },
    {
      id: settingTabs.actions,
      name: 'Actions',
      name_lang: 'vs/instance.string57',
      show: rights.bmreboot || rights.bmon || rights.bmoff || rights.bmdelete || rights.bmundodelete,
    },
    { id: settingTabs.reverse_DNS, name: 'Reverse DNS', name_lang: 'vs/instance.string59' },
  ];
  return dropdownItems.filter((item) => (typeof item.show === 'undefined' ? true : item.show));
};

export const mobileTabs = (
  data,
  upgradeProps,
  { refreshServer, loading },
  type,
  setDeletedIds,
  managerVmRefetch,
  allTags,
  isTags,
  statusOfServer,
  managerVmIsFetching,
  serverSizeData,
  initialActionTab,
) => {
  const rights = useSelector(userRights);
  const bmActionTabs = bmActionsTabs(data, { isCancellation: data.bm_cancelation_scheduled == 1 });
  const items = [
    {
      id: settingTabs.upgrade,
      title: 'Upgrade',
      title_lang: 'vs/instance.string56',
      show: rights.vschange && type === 'vs' && data?.billing_schedule == 'hourly',
      Component: () => (
        <Formik
          initialValues={upgradeInitialValues}
          validationSchema={upgradeProps.validation}
          onSubmit={(values, props) => upgradeProps.handleServerUpgrade(values, props)}
        >
          {(formikProp) => (
            <Upgrade
              fundsData={upgradeProps.fundsData}
              serverSizes={upgradeProps.sizeData}
              formikProp={formikProp}
              sliderData={upgradeProps.sliderData}
              sliderSuccess={upgradeProps.sliderSuccess}
              sliderSizeData={upgradeProps.sliderSizeData}
              data={data}
              loading={
                upgradeProps.updateServerSideLoading ||
                upgradeProps.saveAndChargeCardLoad ||
                upgradeProps.chargeSavedCardLoad
              }
            />
          )}
        </Formik>
      ),
    },
    {
      title: 'Actions',
      title_lang: 'vs/instance.string57',
      id: settingTabs.actions,
      show:
        type === 'bm'
          ? bmActionTabs.some((obj) => obj.show)
          : rights.vsdelete || rights.vsresetpassword || rights.vsreinstall,
      Component: () => (
        <SettingsTabs
          initialTab={initialActionTab}
          tabs={
            type === 'bm'
              ? bmActionTabs
                  .filter((obj) => obj.show)
                  .map((item) => {
                    return {
                      title: item.buttonText,
                      title_lang: item.buttonText,
                      Component: ({ loading: isLoading }) => (
                        <TabContent
                          buttonText={item.buttonText}
                          content={item.content}
                          content2={item.content2}
                          buttonProps={{ onClick: async () => await refreshServer(item.action), isLoading }}
                        />
                      ),
                    };
                  })
              : [
                  {
                    title: 'Cancel VM',
                    title_lang: data?.vs_cancelation_scheduled == 1 ? 'vs/instance.string191' : 'vs/instance.string188',
                    Component: () => <CancelVM data={data} refreshServer={refreshServer} loading={loading} />,
                    show: rights.vsdelete && data?.billing_schedule != 'hourly',
                  },
                  {
                    title: 'Destroy VM',
                    title_lang: 'vs/instance.string100',
                    Component: () => (
                      <DestroyVM refreshServer={refreshServer} loading={loading} setDeletedIds={setDeletedIds} />
                    ),
                    show: rights.vsdelete && data?.billing_schedule == 'hourly',
                  },
                  {
                    title: 'Set new password',
                    title_lang: 'vs/instance.string101',
                    Component: () => <NewPassword refreshServer={refreshServer} loading={loading} />,
                    show: rights.vsresetpassword,
                  },
                  {
                    title: 'Reinstall',
                    title_lang: 'vs/instance.string166',
                    Component: () => (
                      <Reinstall
                        manageVmId={data?.id}
                        billing_schedule={data?.billing_schedule == 'hourly'}
                        refreshServer={refreshServer}
                        loading={loading}
                      />
                    ),
                    show: rights.vsreinstall,
                  },
                ].filter((i) => i.show)
          }
        />
      ),
    },
    {
      title: 'Info / IPs',
      title_lang: 'vs/instance.string58',
      id: settingTabs['info/IPs'],
      Component: () => (
        <Info
          serverVmSizes={upgradeProps.sizeData.find((item) => item.id == data?.size)}
          serverSizeData={serverSizeData}
          type={type}
          data={data}
          managerVmRefetch={managerVmRefetch}
          allTags={allTags}
          isTags={isTags}
          statusOfServer={statusOfServer}
          managerVmIsFetching={managerVmIsFetching}
        />
      ),
    },
    {
      title: 'Reverse DNS',
      title_lang: 'vs/instance.string59',
      id: settingTabs.reverse_DNS,
      Component: () => <ReverseDNS data={data} type={type} />,
    },
    {
      show: type === 'vs',
      title: 'Template / Backups',
      title_lang: 'vs/instance.string60',
      id: settingTabs['templates/backups'],
      Component: () => (
        <Formik initialValues={templateInitialValues}>
          {(formikProp) => <Templates formikProp={formikProp} data={data} managerVmRefetch={managerVmRefetch} />}
        </Formik>
      ),
    },
    {
      show: data?.billing_schedule != 'hourly',
      title: 'Payment terms',
      title_lang: 'vs/instance.string179',
      id: settingTabs['payment_terms'],
      Component: () => <PaymentTerms data={data} />,
    },
  ];
  return items.filter((i) => (typeof i.show === 'undefined' ? true : i.show));
};

export const statisticPanelTabs = {
  statistics: 'statistics',
};

export const statisticPanelMenus = () => [
  { id: statisticPanelTabs.statistics, name: 'Statistics', name_lang: 'vs/instance.string55' },
];
export const social = [
  {
    link: 'https://www.facebook.com/ServerPointCom',
    name: 'fa fa-facebook-square',
  },
  {
    link: 'https://twitter.com/serverpoint/',
    name: 'fa fa-twitter-square',
  },
  {
    link: 'https://plus.google.com/+Serverpoint/',
    name: 'fa fa-google',
  },
];
