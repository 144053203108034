export const UPDATE_THEME = 'UPDATE_THEME';
export const UPDATE_ACTIVE_MODAL = 'UPDATE_ACTIVE_MODAL';
export const TOGGLE_APP_DRAWER = 'TOGGLE_APP_DRAWER';
export const UPDATE_LANGUAGE = 'UPDATE_LANGUAGE';
export const VM_DEPLOYMENT = 'VM_DEPLOYMENT';
export const BM_DEPLOYMENT = 'BM_DEPLOYMENT';
export const VM_DEPLOYMENT_PRICING = 'VM_DEPLOYMENT_PRICING';
export const UPDATE_PRICING = 'UPDATE_PRICING';
export const UPDATE_CPANEL_OS = 'UPDATE_CPANEL_OS';
export const NO_OF_VM = 'NO_OF_VM';
export const USER_UI = 'USER_UI';
export const USER_ACCOUNT = 'USER_ACCOUNT';
export const USER_ACCOUNT_REFETCH = 'USER_ACCOUNT_REFETCH';
export const IS_USER_FETCHING = 'IS_USER_FETCHING';
export const USER_BILLING_ACCOUNT_REFETCH = 'USER_BILLING_ACCOUNT_REFETCH';
export const VM_CREATION = 'VM_CREATION';
export const UPDATE_BM_PRICING = 'UPDATE_BM_PRICING';
export const UPDATE_BM_CPANEL_OS = 'UPDATE_BM_CPANEL_OS';
export const USER_BILLING = 'USER_BILLING';
export const USER_UI_REFETCH = 'USER_UI_REFETCH';
export const VM_REFETCH = 'VM_REFETCH';
export const UPDATE_ACTIVE_SECTION_INDEX = 'UPDATE_ACTIVE_SECTION_INDEX';
export const LOGOUT_USER = 'LOGOUT_USER';


/// SHOPPING CART
export const VS_CART = 'VS_CART';
export const REMOVE_VS_CART = 'REMOVE_VS_CART';
export const CLEAR_ALL_VS_CART = 'CLEAR_ALL_VS_CART';
export const UPDATE_VS_CART = 'UPDATE_VS_CART';

/// DOMAIN CART
export const DOMAIN_CART = 'DOMAIN_CART';
export const REMOVE_DOMAIN_CART = 'REMOVE_DOMAIN_CART';
export const CLEAR_ALL_DOMAIN_CART = 'CLEAR_ALL_DOMAIN_CART';
export const UPDATE_DOMAIN_CART = 'UPDATE_DOMAIN_CART';
