import React from 'react';
import Profile from './Profile';
import Loading from 'components/common/loading/Loading';
import { useQuery } from 'react-query';

export default function ProfileContainer() {
  const { data: areaCodeData, isLoading } = useQuery('/account/profile/2fa/codes');
  const { data: get2fadata, isLoading: loading, refetch } = useQuery('/account/profile/2fa');

  if (isLoading || loading) {
    return <Loading gap={200} />;
  }
  return <Profile areaCodeData={areaCodeData} get2fadata={get2fadata} refetch={refetch} />;
}
