import React from 'react';
import WH from './WH';
import Loading from 'components/common/loading/Loading';
import { useMutation, useQuery } from 'react-query';
import { WH_CREATE_INSTANCE } from './queries';
import { Formik } from 'formik';
import { domainTabs, whCreateInitialValue, whCreateValidationSchema } from 'constants/wh';
import { CustomHook } from 'utils/custom-hook';
import * as Yup from 'yup';

export default function WHContainer() {
  const { mutationProps } = CustomHook();
  const { data: whAppData } = useQuery('/wh/apps');
  const { data: whRegionData } = useQuery('/wh/regions');
  const { data: whSizesData, isLoading: whSizesLoading } = useQuery('/wh/sizes');
  const { data: whSizesPricesData, isLoading: whSizesPricesLoading } = useQuery('/prices/wh/sizes');
  const { data: domainExtData, isLoading: domainExtDataLoad } = useQuery('/prices/domains/extensions');
  const { mutate: whcreateInstance, isLoading } = useMutation(WH_CREATE_INSTANCE);
  const { data: whSizesDataDescription } = useQuery('/wh/sizes/description');

  if (whSizesLoading || whSizesPricesLoading || domainExtDataLoad) {
    return <Loading gap={200} />;
  }
  const handleWHCreateInstance = async (values) => {
    await whcreateInstance(
      {
        domain: values.domain,
        size: values.size.id,
        region: values.region,
        app: values.app,
        options_prepaid: values.options_prepaid,
      },
      mutationProps(),
    );
  };
  const validation = Yup.lazy((values) => {
    let valid = {};
    if (values.domain_checked === domainTabs.register_a_new_domain) {
      valid = {
        ...valid,
        domain: Yup.string().required('Select domain').max(64),
      };
    }
    if (values.domain_checked === domainTabs.transfer_an_existing_domain) {
      valid = {
        ...valid,
        domain: Yup.string().required('Enter domain').max(64),
      };
    }
    return whCreateValidationSchema(valid);
  });

  return (
    <Formik
      initialValues={whCreateInitialValue}
      validationSchema={validation}
      onSubmit={(values) => handleWHCreateInstance(values)}
    >
      {(formikProp) => (
        <WH
          isLoading={isLoading}
          formikProp={formikProp}
          domainExtData={domainExtData}
          whAppData={whAppData}
          whSizesDataDescription={whSizesDataDescription}
          whRegionData={whRegionData}
          whSizesData={whSizesData}
          whSizesPricesData={whSizesPricesData}
        />
      )}
    </Formik>
  );
}
