import React from 'react';
import { PassSC, Pass, ChangePasswords, FormDiv } from './LoginEmail.style';
import { Form } from 'formik';
import { Trans, useTranslation } from 'react-i18next';
import { Highlight } from 'components/vm-deployment/components';
import { Grid } from '@material-ui/core';
import { change_email_form } from 'constants/account-profile';
import Input from 'components/input';
import CustomButton from 'components/common/custom-button';
import 'components/input/input.css';
import { Heading } from 'components/user-management/Heading';
import { P } from 'components/reusable-css';
import { Label, Margin } from 'assets/css/common-styles';

const LoginEmailForm = ({ formikProp, username, loginEmailLoading, width }) => {
  const { t } = useTranslation();
  const { values, handleChange, handleBlur, errors, touched } = formikProp;

  return (
    <Form>
      <PassSC>
        <Pass>
          <Heading>
            <Trans i18nKey="account/profile.string15" components={{ highlight: <Highlight /> }} />
          </Heading>
          <ChangePasswords>
            <P>
              <Trans i18nKey={'account/profile.string16'} values={{ username }} />
            </P>
            <FormDiv>
              <Grid container>
                <Grid xs={6} sm={3} lg={3} xl={2}>
                  <Label>{t('account/profile.string3')}</Label>
                </Grid>
                <Grid xs={6} sm={2} lg={2} xl={3}>
                  <Margin>{username}</Margin>
                </Grid>
              </Grid>
              {change_email_form.map((item) => (
                <Grid key={item.name} container>
                  <Grid xs={12} sm={3} lg={3} xl={2}>
                    <Label>{t(item.lang)}</Label>
                  </Grid>
                  <Grid xs={12} sm={10} md={6} lg={5} xl={3}>
                    <Input
                      styling={width}
                      className="custom_inputs"
                      type={item.type}
                      name={item.name}
                      value={values[item.name]}
                      placeholder={t(item.placeholder_lang)}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors[item.name]}
                      touched={touched[item.name]}
                    />
                  </Grid>
                </Grid>
              ))}
            </FormDiv>
            <CustomButton type="submit" buttonStyles={{ padding: ' 9px 55px' }} isLoading={loginEmailLoading} />
          </ChangePasswords>
        </Pass>
      </PassSC>
    </Form>
  );
};

export default LoginEmailForm;
