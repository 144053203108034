import { DOMAIN_CART, REMOVE_DOMAIN_CART, CLEAR_ALL_DOMAIN_CART, UPDATE_DOMAIN_CART } from 'store/types';

export const addDomain_CART = (payload) => (dispatch) => {
  dispatch({
    type: DOMAIN_CART,
    payload,
  });
};

export const removeDomain_CART = (payload) => (dispatch) => {
  dispatch({
    type: REMOVE_DOMAIN_CART,
    payload,
  });
};

export const clearAllDomain_CART = (payload) => (dispatch) => {
  dispatch({
    type: CLEAR_ALL_DOMAIN_CART,
    payload,
  });
};

export const updateDomain_CART = (payload) => (dispatch) => {
  dispatch({
    type: UPDATE_DOMAIN_CART,
    payload,
  });
};
