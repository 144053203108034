import React, { useCallback, useEffect, useState } from 'react';
import * as Styles from './DNS.style';
import { Heading } from 'assets/css/common-styles';
import CustomButton from 'components/common/custom-button/custom-button';
import { Trans, useTranslation } from 'react-i18next';
import { H6 } from 'components/reusable-css';
import AddDomainModal from 'components/dns/AddDomainModal';
import { useMutation, useQuery } from 'react-query';
import { ADD_DOMAIN } from 'components/dns/queries';
import hooks from 'hooks';
import Loading from 'components/common/loading/Loading';
import { Icon } from 'semantic-ui-react';
import DNSList from 'components/dns/DNSList';
import ConfigurationDNS from 'components/dns/ConfigurationDNS';
import DNSNotFound from 'components/dns/DNSNotFound';
import { Highlight } from 'components/vm-deployment/components';

const nsDomain = ['string10', 'string11', 'string12'];

const DNS = ({ instance_id }) => {
  const { t } = useTranslation();
  const [openDomain, setOpenDomain] = useState('');
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [openConfigurationPanel, setOpenConfigurationPanel] = useState(false);
  const { mutationProps } = hooks();
  const { mutate: addDomain, isLoading: loadAddDomain } = useMutation(ADD_DOMAIN);
  let apiEndPoint = instance_id ? `/dns/instance/${instance_id}` : '/dns/instance';
  const { data, isLoading, refetch, isError, isFetching } = useQuery(apiEndPoint);
  const { data: ipData } = useQuery('/dns/ip');

  const escFunction = useCallback((event) => {
    if (event.key === 'Escape') {
      setOpenConfigurationPanel(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);
    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, []);

  const handleSuccess = (resetForm) => {
    if (resetForm) {
      refetch();
      setIsOpenModal(false);
      resetForm();
    }
  };

  const addDomainApi = async (values, resetForm) => {
    await addDomain(
      { domain: values.domain, ip_number: values.ip_number },
      mutationProps(() => {}, {
        onSuccess: () => handleSuccess(resetForm),
      }),
    );
  };

  if (isLoading) {
    return <Loading gap={200} />;
  }

  return (
    <Styles.DNSSC>
      <Styles.HeadingWithButton isError={isError || data?.dns_domains?.length == 0}>
        <div>
          {!isError && data?.dns_domains?.length > 0 && (
            <Styles.Heading>
              <Heading>
                <Trans i18nKey="dns/instance.string1" components={{ highlight: <Highlight /> }} />
              </Heading>
            </Styles.Heading>
          )}
          {!isError && data?.dns_domains?.length > 0 && (
            <Styles.StepDescription>{t('dns/instance.string2')}</Styles.StepDescription>
          )}
        </div>
        <div className="button">
          <CustomButton title={t('dns/instance.string3')} onClick={() => setIsOpenModal(true)} />
        </div>
      </Styles.HeadingWithButton>
      <Styles.ListsContainer>
        {isError || data?.dns_domains?.length == 0 ? (
          <DNSNotFound />
        ) : (
          <>
            {data?.dns_domains?.map((val) => (
              <DNSList
                fetchDNSLoading={isFetching}
                refectDNS={refetch}
                key={data.id}
                data={val}
                openConfigurationPanel={openConfigurationPanel}
                setOpenConfigurationPanel={setOpenConfigurationPanel}
                setOpenDomain={setOpenDomain}
              />
            ))}
            <Styles.BottomWarning>
              <Styles.BottomWarningStatus>
                <div className="flexCol">
                  <Icon name="lightbulb" />
                  <H6>{t('dns/instance.string14')}</H6>
                </div>
                <div className="margin">
                  {nsDomain.map((data, i) => (
                    <div className="flexCol" key={i}>
                      <Icon name="circle" />
                      <H6>{t(`dns/instance.${data}`)}</H6>
                    </div>
                  ))}
                </div>
              </Styles.BottomWarningStatus>
            </Styles.BottomWarning>
          </>
        )}
      </Styles.ListsContainer>

      <AddDomainModal
        ipData={ipData}
        open={isOpenModal}
        loading={loadAddDomain}
        toggle={() => setIsOpenModal(false)}
        handleDomain={async (values, resetForm) => await addDomainApi(values, resetForm)}
      />
      <ConfigurationDNS
        refecthMainList={refetch}
        ipData={ipData}
        isOpen={openConfigurationPanel}
        openDomain={openDomain}
        data={data}
        isClose={setOpenConfigurationPanel}
      />
    </Styles.DNSSC>
  );
};
export default DNS;
