import React from 'react';
import classes from 'assets/css/payment-form.module.css';
import Checkbox from 'components/common/radio-button';
import { Heading } from 'components/vm-deployment/cPanel-card/CPanelCard.style';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

export default function PaymentMethod({ onClick, style, heading, setCheckedValue, data, checkboxValue }) {
  return (
    <div onClick={onClick} className={classes.payment_box} style={style}>
      <Checkbox checkbox={checkboxValue} setCheckedValue={setCheckedValue} data={data} />
      <Heading style={style}>{heading}</Heading>
      <ArrowDropDownIcon fontSize={'medium'} />
    </div>
  );
}
