import React, { useState } from 'react';
import { get } from 'utils';
import * as Styles from 'components/issues/Issues.style';
import { Highlight, StepLabel } from 'components/vm-deployment/components';
import { Trans, useTranslation } from 'react-i18next';
import MultipleItems from './MultipleItems';

export const RecursiveInputs = ({ steps, value, setValue, formikProps, stepType }) => {
  const [show, setShow] = useState(true);

  return (
    <>
      {steps.map((step) => (
        <MultipleItems
          stepType={stepType}
          formikProps={formikProps}
          show={show}
          setShow={setShow}
          value={value}
          setValue={setValue}
          step={step}
          key={step.id}
        />
      ))}
    </>
  );
};

const DynamicInputs = ({ data, setValue, value, stepType, formikProps }) => {
  const { t } = useTranslation();
  return (
    <Styles.IssuesSC>
      <StepLabel id={data.step_title_en} stepCount={t(`tickets/create.${data.api_language_string_step_title}`)}>
        <Trans
          i18nKey={`tickets/create.${data.api_language_string_main_title}`}
          components={{ highlight: <Highlight /> }}
        />
      </StepLabel>
      {data.api_language_string_sub_title && (
        <Styles.Description>{t(`tickets/create.${data.api_language_string_sub_title}`)}</Styles.Description>
      )}
      <RecursiveInputs
        stepType={stepType}
        formikProps={formikProps}
        steps={get(data, stepType, [])}
        setValue={setValue}
        value={value}
      />
    </Styles.IssuesSC>
  );
};

export default React.memo(DynamicInputs);
