import React from 'react';
import * as Styles from 'pages/member/domain-checkout/DomainCheckout.style';
import { Trans, useTranslation } from 'react-i18next';
import { H5, H6, P } from 'components/reusable-css';
import { useDispatch, useSelector } from 'react-redux';
import Heading from 'components/Heading/Heading';
import { Icon } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';
import { navLinks } from 'utils/nav-links-util';
import Select from 'components/select';
import { UpperCaseWord } from 'assets/css/common-styles';
import { removeDomain_CART, updateDomain_CART } from 'store/actions/domain-cart';
import CustomButton from 'components/common/custom-button/custom-button';

const CartSummary = ({ totalPrice, setTerm }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const domainCart = useSelector((state) => state.domainCart.domain_deploy);
  const termData = [
    { text: t('domain/instance/summary.string45'), value: 1 },
    { text: t('domain/instance/summary.string46', { value: 2 }), value: 2 },
    { text: t('domain/instance/summary.string46', { value: 3 }), value: 3 },
    { text: t('domain/instance/summary.string46', { value: 4 }), value: 4 },
    { text: t('domain/instance/summary.string46', { value: 5 }), value: 5 },
    { text: t('domain/instance/summary.string46', { value: 6 }), value: 6 },
    { text: t('domain/instance/summary.string46', { value: 7 }), value: 7 },
    { text: t('domain/instance/summary.string46', { value: 8 }), value: 8 },
    { text: t('domain/instance/summary.string46', { value: 9 }), value: 9 },
    { text: t('domain/instance/summary.string46', { value: 10 }), value: 10 },
  ];
  const handleRemoveCart = (index) => {
    dispatch(removeDomain_CART(index));
  };

  const handleTerm = (term, index) => {
    setTerm(term);
    dispatch(updateDomain_CART({ index, term }));
  };

  if (domainCart.length == 0) {
    history.replace(navLinks.domain_instance.link);
  }

  return (
    <Styles.CartSummaryContainer>
      <Styles.CartButtonRow>
        <CustomButton
          title={'Back to domain search'}
          type="submit"
          buttonStyles={{ float: 'right', marginBottom: '1em' }}
          onClick={() => history.replace(navLinks.domain_instance.link)}
        />
      </Styles.CartButtonRow>

      <Heading name="domain/instance/summary.string1">
        <Styles.CartRow>
          <Styles.CartTable>
            <Styles.CartTableContent>
              {domainCart.map((data, index) => (
                <Styles.CartTableRow key={index}>
                  <div className="single_row">
                    <div style={{ width: '50%', display: 'flex' }}>
                      <Icon name="circle" className="circle" />
                      <div>
                        <H6 className="bold" style={{ marginBottom: '.3em' }}>
                          {data.new_domain}
                        </H6>
                        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                          <div style={{ display: 'flex', alignItems: 'center', marginRight: '1em' }}>
                            <P className="m-0 mr-1">{t('domain/instance/summary.string3')}</P>
                            {data.privacy == 'privacy_protection' ? (
                              <>
                                <P className="m-0 mr-1">{t('domain/instance/summary.string48')}</P>
                                <Icon name="check" color="green" className="no-icon" />
                              </>
                            ) : (
                              <>
                                <P className="m-0 mr-1">{t('domain/instance/summary.string49')}</P>
                                <Icon name="close" color="red" />
                              </>
                            )}
                          </div>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <P className="m-0 mr-1">{t('domain/instance/summary.string4')}</P>
                            {data.renew == 'auto_renew' ? (
                              <>
                                <P className="m-0 mr-1">{t('domain/instance/summary.string48')}</P>
                                <Icon name="check" color="green" className="no-icon" />
                              </>
                            ) : (
                              <>
                                <P className="m-0 mr-1">{t('domain/instance/summary.string49')}</P>
                                <Icon name="close" color="red" />
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <H6 className="bold" style={{ width: '20%', textAlign: 'right', padding: '0 .5em' }}>
                      <Trans
                        i18nKey={'domain/instance/summary.string6'}
                        values={{
                          price: (data.price * data.term).toFixed(2),
                          currency: 'USD',
                          currency_symbol: '$',
                        }}
                        components={{
                          span: <span style={{ fontSize: '12px', fontWeight: 'normal' }} />,
                          uppercase: <UpperCaseWord />,
                        }}
                      />
                    </H6>
                    <div style={{ width: '25%', textAlign: 'right', padding: '0 1em' }}>
                      <Select
                        value={data.term}
                        options={termData}
                        onChange={(e) => handleTerm(e.target.value, index)}
                        styling={{ width: '7.2em', marginTop: 0, height: '2.2em', padding: '5px' }}
                      />
                    </div>
                    <Styles.RemoveCart onClick={() => handleRemoveCart(index)}>
                      <Icon name="trash alternate outline" />
                    </Styles.RemoveCart>
                  </div>
                </Styles.CartTableRow>
              ))}
            </Styles.CartTableContent>
          </Styles.CartTable>
          <div style={{ width: '19%' }}>
            <Styles.PriceTable>
              <Styles.TotalPriceTable>
                <H5>{t(`domain/instance/summary.string7`)}</H5>
                <H5>
                  <Trans
                    i18nKey={'domain/instance/summary.string6'}
                    values={{
                      price: totalPrice.toFixed(2),
                      currency: 'USD',
                      currency_symbol: '$',
                    }}
                    components={{ uppercase: <UpperCaseWord /> }}
                  />
                </H5>
              </Styles.TotalPriceTable>
            </Styles.PriceTable>
          </div>
        </Styles.CartRow>
      </Heading>
    </Styles.CartSummaryContainer>
  );
};

export default CartSummary;
