import React from 'react';
import RecoverableVS from './RecoverableVS';
import { RECOVERABLE_VS } from 'components/recoverable-vs/queries';
import { useQuery } from 'react-query';
import Loading from 'components/common/loading';

const RecoverableVSContainer = ({ refetchMenu }) => {
  const { data: recoverableVs, isLoading } = useQuery('RECOVERABLE_VS', RECOVERABLE_VS);
  if (isLoading) {
    return <Loading gap={200} />;
  }
  return <RecoverableVS refetchMenu={refetchMenu} recoverableVs={recoverableVs} />;
};
export default RecoverableVSContainer;
