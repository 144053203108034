import React from 'react';
import * as Styles from 'pages/member/domain-checkout/DomainCheckout.style';
import Heading from 'components/Heading/Heading';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { cityData, initialValuesContactDetail, nameData, validationSchemaContactDetail } from './utils';
import { Grid, useMediaQuery } from '@material-ui/core';
import { Label } from 'assets/css/common-styles';
import Input from 'components/input';
import CustomButton from 'components/common/custom-button/custom-button';
import classes from 'assets/css/payment-form.module.css';
import Select from 'components/select';
import { H6 } from 'components/reusable-css';

const ContactDetail = () => {
  const { t } = useTranslation();
  const multiTextbox = useMediaQuery('(max-width:450px)');
  const commonWidth = {
    width: multiTextbox ? '95%' : '22rem',
    paddingRight: multiTextbox ? '0rem' : '0.5rem',
  };

  return (
    <Styles.ContactMethodContainer>
      <Heading name="domain/instance/summary.string8">
        <Formik
          initialValues={initialValuesContactDetail}
          validationSchema={validationSchemaContactDetail}
          onSubmit={(values) => {
            console.log(values);
          }}
        >
          {({ values, handleBlur, handleChange, errors, touched, isValid, dirty }) => (
            <Styles.ContactDetailContainer>
              <Styles.FormDiv>
                <Grid className={classes.main} container spacing={0}>
                  <Grid xs={12} sm={12} md={3} lg={3} xl={2}>
                    <Label> {t('domain/instance/summary.string9')}</Label>
                  </Grid>
                  <Grid item container xs={12} sm md lg xl>
                    {nameData.map((item) => (
                      <Input
                        type={item.type}
                        key={item.name}
                        styling={commonWidth}
                        name={item.name}
                        value={values[item.name]}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors[item.name]}
                        touched={touched[item.name]}
                        placeholder={t(item.placeholder_lang)}
                      />
                    ))}
                  </Grid>
                </Grid>
                <Grid className={classes.main} container spacing={0}>
                  <Grid xs={12} sm={12} md={3} lg={3} xl={2}>
                    <Label>{t('domain/instance/summary.string10')}</Label>
                  </Grid>
                  <Grid xs={12} sm={10} md={6} lg={5} xl={3}>
                    <Input
                      className="custom_inputs"
                      type={'text'}
                      styling={commonWidth}
                      name={'organization'}
                      value={values['organization']}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors['organization']}
                      touched={touched['organization']}
                      placeholder={t('domain/instance/summary.string18')}
                    />
                  </Grid>
                </Grid>
                <Grid className={classes.main} container spacing={0}>
                  <Grid xs={12} sm={12} md={3} lg={3} xl={2}>
                    <Label>{t('domain/instance/summary.string11')}</Label>
                  </Grid>
                  <Grid style={{ position: 'relative' }} item container xs={12} sm md lg xl>
                    <Input
                      styling={commonWidth}
                      className="custom_inputs"
                      type={'text'}
                      name={'address_first_line'}
                      value={values['address_first_line']}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors['address_first_line']}
                      touched={touched['address_first_line']}
                      placeholder={t('domain/instance/summary.string19')}
                    />
                  </Grid>
                </Grid>
                <Grid className={classes.main} container spacing={0}>
                  <Grid xs={12} sm={12} md={3} lg={3} xl={2} />
                  <Grid item container xs={12} sm md lg xl>
                    <Input
                      type={'text'}
                      styling={commonWidth}
                      name={'address_second_line'}
                      className="custom_inputs"
                      value={values['address_second_line']}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors['address_second_line']}
                      touched={touched['address_second_line']}
                      placeholder={t('domain/instance/summary.string20')}
                    />
                  </Grid>
                </Grid>
                <Grid className={classes.main} container spacing={0}>
                  <>
                    <Grid xs={12} sm={12} md={3} lg={3} xl={2} />
                    <Grid item container xs={12} sm md lg xl>
                      {cityData.map((item) => (
                        <Input
                          styling={{
                            width: multiTextbox ? '95%' : item.name === 'city' ? '22rem' : '11rem',
                            paddingRight: multiTextbox ? '0rem' : '0.5rem',
                          }}
                          type={'text'}
                          key={item.name}
                          name={item.name}
                          value={values[item.name]}
                          className="custom_inputs"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors[item.name]}
                          touched={touched[item.name]}
                          placeholder={t(item.placeholder_lang)}
                        />
                      ))}
                    </Grid>
                  </>
                </Grid>
                <Grid className={classes.main} container spacing={0}>
                  <Grid xs={12} sm={12} md={3} lg={3} xl={2}>
                    <Label>{t('domain/instance/summary.string12')}</Label>
                  </Grid>
                  <Grid style={{ position: 'relative' }} item container xs={12} sm md lg xl>
                    <Select
                      options={[{ value: '+00', text: '+00' }]}
                      name={'phone_number_area_code'}
                      value={values['phone_number_area_code']}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors['phone_number_area_code']}
                      touched={touched['phone_number_area_code']}
                    />
                    <Input
                      styling={{ marginTop: '-6px', marginLeft: '10px', width: '15.5rem' }}
                      type={'text'}
                      name={'phone_number'}
                      className="custom_inputs"
                      value={values['phone_number']}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors['phone_number']}
                      touched={touched['phone_number']}
                      placeholder={t('domain/instance/summary.string25')}
                    />
                  </Grid>
                </Grid>
                <Grid className={classes.main} container spacing={0}>
                  <Grid xs={12} sm={12} md={3} lg={3} xl={2}>
                    <Label>{t('domain/instance/summary.string13')}</Label>
                  </Grid>
                  <Grid style={{ position: 'relative' }} item container xs={12} sm md lg xl>
                    <Input
                      type={'text'}
                      styling={commonWidth}
                      name={'email_address'}
                      className="custom_inputs"
                      value={values['email_address']}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors['email_address']}
                      touched={touched['email_address']}
                      placeholder={t('domain/instance/summary.string26')}
                    />
                  </Grid>
                </Grid>
              </Styles.FormDiv>
              <div style={{ display: 'flex', alignItems: 'center', fontWeight:'bold' }}>
                <H6 className='mt-2 mr-5'>{t('domain/instance/summary.string43')}</H6>
                <CustomButton disabled={!isValid || !dirty} type="submit" buttonStyles={{ padding: ' 9px 55px' }} />
              </div>
            </Styles.ContactDetailContainer>
          )}
        </Formik>
      </Heading>
    </Styles.ContactMethodContainer>
  );
};

export default ContactDetail;
