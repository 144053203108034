import React from 'react';
import Password from './change-password/ChangePassword';
import LoginEmail from './login-email/LoginEmail';
import EditEmailInfo from './edit-email-info/EditEmailInfo';
import PersonalContactInfo from './personal-contact-info/PersonalContactInfo';
import { ProfileSC } from './Profile.style';
import { getLocalStorageValues } from 'constants/local-storage';
import { CustomHook } from 'utils/custom-hook';
import { useSelector } from 'react-redux';
import { userRights } from 'store/selectors/user-details';
import ConditionalFragment from 'components/conditional-fragment';
import Authentication from './2FA-authentication/Authentication';

const Profile = ({  areaCodeData, get2fadata, refetch }) => {
  let { userName: username } = getLocalStorageValues();
  const { fixedTextboxWidth } = CustomHook();
  const rights = useSelector(userRights);

  return (
    <ProfileSC>
      <ConditionalFragment condition={rights.subuserpass}>
        <Password width={fixedTextboxWidth} username={username?.userName} />
      </ConditionalFragment>
      <Authentication get2fadata={get2fadata} areaCodeData={areaCodeData} refetch={refetch} />
      <ConditionalFragment condition={rights.subuserloginwithemail}>
        <LoginEmail width={fixedTextboxWidth} username={username?.userName} />
      </ConditionalFragment>
      <EditEmailInfo rights={rights} width={fixedTextboxWidth} />
      <PersonalContactInfo rights={rights} width={{ width: '21.5rem' }} />
    </ProfileSC>
  );
};
export default Profile;
