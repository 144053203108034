import React, { useEffect, useState } from 'react';
import * as Styles from 'components/manage-vm/settings-panel/SettingsPanel.style';
import { upgradeInitialValues, templateInitialValues } from 'components/manage-vm/utils';
import Loading from 'components/common/loading';
import { Formik } from 'formik';
import ConditionalFragment from 'components/conditional-fragment';
import { Actions, Info } from './components';
import Upgrade from './upgrade';
import ReverseDNS from './reverse-dns';
import Templates from './templates';
import { TabsHeader } from '../components';
import { BASE_URL, settingTabs } from 'constants/index';
import { useSelector } from 'react-redux';
import { userRights } from 'store/selectors/user-details';
import PaymentTerms from './payment-terms';
import axios from 'axios';

const DesktopSettingsPanel = ({
  isLoading,
  sizeData,
  isClose,
  data,
  fundsData,
  sliderData,
  sliderSuccess,
  sliderSizeData,
  menuOptions,
  type,
  upgradeProps,
  refreshServer,
  serverActionsLoading,
  initialTab,
  setDeletedIds,
  managerVmRefetch,
  allTags,
  isTags,
  statusOfServer,
  managerVmIsFetching,
  serverSizeLoading,
  serverSizeData,
  initialActionTab,
}) => {
  const rights = useSelector(userRights);
  const initial =
    type == 'bm'
      ? initialTab || 'info/IPs'
      : initialTab ||
        (rights.vschange && data?.billing_schedule == 'hourly' ? settingTabs.upgrade : settingTabs.actions);
  const [isShowConfigurationPanel, setIsShowConfigurationPanel] = useState(initial);
  const [paymentTerm, setPaymentTerm] = useState(1);
  const [isChnaged, setIschanged] = useState(1);

  const handlePaymentTerm = async () => {
    try {
      const payterm = await axios.get(`${BASE_URL}/billing/${data?.id}/payment_term`);
      setPaymentTerm(Number(payterm?.data?.term));
    } catch (error) {
      setPaymentTerm(paymentTerm);
    }
  };

  useEffect(() => {
    handlePaymentTerm();
  }, [isChnaged]);

  if (isLoading || serverSizeLoading) {
    return <Loading gap={50} />;
  }
  return (
    <Styles.SettingPanel>
      <TabsHeader
        data={data}
        menuOptions={menuOptions}
        activePanelValue={isShowConfigurationPanel}
        setActivePanelValue={setIsShowConfigurationPanel}
        isClose={isClose}
      />
      <ConditionalFragment condition={isShowConfigurationPanel === 'upgrade'}>
        <Formik
          initialValues={upgradeInitialValues}
          validationSchema={upgradeProps.validation}
          onSubmit={(values, props) => upgradeProps.handleServerUpgrade(values, props)}
        >
          {(formikProp) => (
            <Upgrade
              fundsData={fundsData}
              serverSizes={sizeData}
              formikProp={formikProp}
              sliderData={sliderData}
              sliderSuccess={sliderSuccess}
              sliderSizeData={sliderSizeData}
              data={data}
              loading={
                upgradeProps.updateServerSideLoading ||
                upgradeProps.saveAndChargeCardLoad ||
                upgradeProps.chargeSavedCardLoad
              }
            />
          )}
        </Formik>
      </ConditionalFragment>
      <ConditionalFragment condition={isShowConfigurationPanel === settingTabs.actions}>
        <Actions
          data={data}
          refreshServer={refreshServer}
          loading={serverActionsLoading}
          type={type}
          setDeletedIds={setDeletedIds}
          initialTab={initialActionTab}
          managerVmRefetch={managerVmRefetch}
        />
      </ConditionalFragment>
      <ConditionalFragment condition={isShowConfigurationPanel === settingTabs['info/IPs']}>
        <Info
          serverSizeData={serverSizeData}
          serverVmSizes={sizeData.find((item) => item.id == data?.size)}
          data={data}
          type={type}
          managerVmRefetch={managerVmRefetch}
          allTags={allTags}
          isTags={isTags}
          statusOfServer={statusOfServer}
          managerVmIsFetching={managerVmIsFetching}
        />
      </ConditionalFragment>
      <ConditionalFragment condition={isShowConfigurationPanel === settingTabs.reverse_DNS}>
        <ReverseDNS data={data} type={type} />
      </ConditionalFragment>
      <ConditionalFragment condition={isShowConfigurationPanel === settingTabs['templates/backups']}>
        <Formik initialValues={templateInitialValues}>
          {(formikProp) => <Templates formikProp={formikProp} data={data} managerVmRefetch={managerVmRefetch} />}
        </Formik>
      </ConditionalFragment>
      <ConditionalFragment condition={isShowConfigurationPanel === settingTabs.payment_terms}>
        <PaymentTerms
          setIschanged={setIschanged}
          data={data}
          setPaymentTerm={setPaymentTerm}
          paymentTerm={paymentTerm}
        />
      </ConditionalFragment>
    </Styles.SettingPanel>
  );
};

export default React.memo(DesktopSettingsPanel);
