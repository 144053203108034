import React from 'react';
import * as Styles from 'pages/member/manage-vm/ManageVM.style';
import { Network } from './index';
import { P } from 'components/reusable-css';

const VmNetworks = ({ serverIpDetails, data }) => {
  return (
    <Styles.Network>
      <Styles.FlexColumn>
        {(serverIpDetails || data).networks.map((network) => {
          return network.eth0?.map((eth) => {
            return eth.ipv4?.map((ip) => <Network ip={ip} key={ip.ip} />);
          });
        })}
      </Styles.FlexColumn>
      <Styles.FlexColumn>
        {(serverIpDetails || data).networks.map((network) => {
          return network.eth1?.map((eth) => {
            return eth.ipv4?.map((ip) => (
              <P className="network-border" key={ip.ip}>
                {ip.ip}
              </P>
            ));
          });
        })}
      </Styles.FlexColumn>
    </Styles.Network>
  );
};

export { VmNetworks };
