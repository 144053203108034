import React from 'react';
import './input.css';
import { ErrorMessage } from 'formik';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { UpperCaseWord } from 'assets/css/common-styles';
import { CustomInput } from '@sidraaabdullah/test-shared-library';

const Input = ({
  styling,
  placeholder,
  touched,
  error,
  name,
  value,
  onChange,
  className,
  isFormIk = true,
  handleClickShowPassword,
  PasswordInput,
  inputProps = {},
  loading = false,
  EndAdornment = () => {},
  onEnter,
  validateBorderColor,
  validateBorderCondition,
  validateLoading,
  dataTooltip,
  tooltipStyles,
  onBlur,
  onFocus,
  largerFont,
  borderWidth,
  borderRadius,
  borderBottomWidth,
  isValidate = true,
  errorStyles = {},
  placeholderClass = false,
  disabled = false,
  defaultDropdown = 'on',
  disabledField,
  autocomplete,
  ...rest
}) => {
  const { t } = useTranslation();
  const userAccount = useSelector((state) => state.user.account);
  return (
    <CustomInput
      styling={styling}
      placeholder={placeholder}
      touched={touched}
      error={error}
      name={name}
      value={PasswordInput ? value : (value + '')?.trim().length === 0 ? '' : t(value)}
      onChange={onChange}
      className={className}
      isFormIk={isFormIk}
      handleClickShowPassword={handleClickShowPassword}
      PasswordInput={PasswordInput}
      inputProps={inputProps}
      loading={loading}
      EndAdornment={EndAdornment}
      onEnter={onEnter}
      validateBorderColor={validateBorderColor}
      validateBorderCondition={validateBorderCondition}
      validateLoading={validateLoading}
      dataTooltip={dataTooltip}
      tooltipStyles={tooltipStyles}
      onBlur={onBlur}
      onFocus={onFocus}
      largerFont={largerFont}
      borderWidth={borderWidth}
      borderRadius={borderRadius}
      borderBottomWidth={borderBottomWidth}
      isValidate={isValidate}
      errorStyles={errorStyles}
      placeholderClass={placeholderClass}
      disabled={disabled}
      defaultDropdown={defaultDropdown}
      errorMessage={
        isFormIk ? (
          <ErrorMessage
            name={name}
            render={(msg) => (
              <span className="error_messageSty">
                <Trans
                  i18nKey={msg}
                  values={{
                    currency: userAccount.account_currency,
                    currency_symbol: userAccount.account_currency_symbol,
                  }}
                  components={{ uppercase: <UpperCaseWord /> }}
                />
              </span>
            )}
          />
        ) : (
          <>
            {error && (
              <span className="error_messageSty">
                <Trans
                  i18nKey={error || 'Wrong format'}
                  values={{
                    currency: userAccount.account_currency,
                    currency_symbol: userAccount.account_currency_symbol,
                  }}
                  components={{ uppercase: <UpperCaseWord /> }}
                />
              </span>
            )}
          </>
        )
      }
      string12={t('string12')}
      disabledField={disabledField}
      autocomplete={autocomplete}
      {...rest}
    />
  );
};

export default Input;
