import React, { useState } from 'react';
import MobileTemplate from '../mobile-template';
import { BackupCard } from './index';
import { BACKUP_ACTIONS } from 'components/manage-vm/queries';
import { useMutation } from 'react-query';
import { restore_backup } from 'constants/index';
import { useSelector } from 'react-redux';
import { userRights } from 'store/selectors/user-details';
import ConditionalFragment from 'components/conditional-fragment';
import { CustomHook } from 'utils/custom-hook';

const WithBackup = ({ backups, isPermanent, backupDelete, backupDeleteLoad, data }) => {
  const [isOpenConfirmationWarning, setIsOpenConfirmationWarning] = useState(false);
  const { mutationProps, isMobile } = CustomHook();
  const rights = useSelector(userRights);
  
  const { mutate: backupAction, isLoading: backupActionLoad } = useMutation(BACKUP_ACTIONS, {
    ...mutationProps('', {
      error: 'error',
      onSuccess: () => {
        setIsOpenConfirmationWarning(false);
      },
    }),
  });
  const restoreBackup = async (backup) => {
    await backupAction({ id: data.id, backup, action: restore_backup });
  };

  return (
    <>
      <ConditionalFragment condition={!isMobile}>
        <BackupCard
          backupData={backups}
          isPermanent={isPermanent}
          backupDelete={backupDelete}
          data={data}
          backupDeleteLoad={backupDeleteLoad}
          restoreBackup={restoreBackup}
          restoreBackupLoad={backupActionLoad}
          rights={rights}
          setIsOpenConfirmationWarning={setIsOpenConfirmationWarning}
          isOpenConfirmationWarning={isOpenConfirmationWarning}
        />
      </ConditionalFragment>
      <ConditionalFragment condition={isMobile}>
        <MobileTemplate
          backupData={backups}
          isPermanent={isPermanent}
          backupDelete={backupDelete}
          data={data}
          restoreBackup={restoreBackup}
          restoreBackupLoad={backupActionLoad}
          backupDeleteLoad={backupDeleteLoad}
          rights={rights}
          setIsOpenConfirmationWarning={setIsOpenConfirmationWarning}
          isOpenConfirmationWarning={isOpenConfirmationWarning}
        />
      </ConditionalFragment>
    </>
  );
};
export { WithBackup };
