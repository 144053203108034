/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-01-28 02:24:25
 * @modify date 2021-02-05 11:18:31
 */

import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Button } from 'semantic-ui-react';

import { deviceBreakpoints } from 'variables';
import { dynamicStyles } from 'components/reusable-css';

export const TriggerContainer = styled.div`
  display: grid;
  justify-items: center;
  align-content: center;
  cursor: pointer;
  width: 6.5em;
  &:hover {
    text-decoration: underline;
  }
  .user-icon {
    font-size: ${(props) => props.theme.default?.top_bar_right_icon_width};
    width: ${(props) => props.theme.default?.top_bar_right_icon_width};
    height: ${(props) => props.theme.default?.top_bar_right_icon_height};
    ${(props) => dynamicStyles(props.theme.resolutions, 'top_bar_right_icon_width', 'font-size')};
    margin: 1px 0px 2px 0px;
  }
`;

export const AccountLabel = styled.div`
  display: inline;
  font-size: ${(props) => props.theme.default?.top_bar_right_icon_font};
  font-weight: bold;
  color: ${(props) => props.theme.gray.shade10};

  i {
    margin-left: 2px;
  }
  ${(props) => dynamicStyles(props.theme.resolutions, 'top_bar_right_icon_font', 'font-size')};
`;

export const AccountLabelLink = styled(Link)`
  font-size: ${(props) => props.theme.default?.account_submenu_font};
  line-height: ${(props) => props.theme.default?.account_submenu_font_line_height};
  ${(props) =>
    dynamicStyles(
      props.theme.resolutions,
      'account_submenu_font_line_height',
      'line-height',
      'account_submenu_font',
      'font-size',
    )};
`;

export const AchorTag = styled.a`
  display: flex;
  padding: 0 20px;
  align-items: center;
  color: ${(props) => props.theme.gray.shade0};
  div {
    font-size: ${(props) => props.theme.default?.account_submenu_font};
    line-height: ${(props) => props.theme.default?.account_submenu_font_line_height};
    ${(props) =>
      dynamicStyles(
        props.theme.resolutions,
        'account_submenu_font_line_height',
        'line-height',
        'account_submenu_font',
        'font-size',
      )};
  }
  i {
    margin-right: 15px;
    display: table;
  }

  &:hover {
    color: ${(props) => props.theme.gray.shade0};
    background: ${(props) => props.theme.gray.shadeE};
  }
`;

export const LinkSC = styled.a`
  display: flex;
  padding: 0 20px;
  justify-content: flex-start;
  align-items: center;
  color: ${(props) => props.theme.gray.shade0};
  div {
    font-size: ${(props) => props.theme.default?.account_submenu_font};
    line-height: ${(props) => props.theme.default?.account_submenu_font_line_height};
    ${(props) =>
      dynamicStyles(
        props.theme.resolutions,
        'account_submenu_font_line_height',
        'line-height',
        'account_submenu_font',
        'font-size',
      )};
  }

  i {
    margin-right: 15px;
    display: table;
  }

  &:hover {
    color: ${(props) => props.theme.gray.shade0};
    background: ${(props) => props.theme.gray.shadeE};
  }
`;

export const HeaderSC = styled.div`
  display: flex;
  background: ${(props) => props.theme.gray.shadeF};
  height: ${(props) => props.theme.default?.top_bar_height};
  ${(props) => dynamicStyles(props.theme.resolutions, 'top_bar_height', 'height')};
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${(props) => props.theme.dividerColor};

  @media only screen and (max-width: ${deviceBreakpoints.mLaptop}px) {
    box-shadow: ${(props) => props.theme.boxShadow1};
    border-bottom: 1px solid ${(props) => props.theme.gray.shade0};
    z-index: 999;
    right: 0;
    left: 0;
    position: fixed;
  }
`;

export const AppDrawerToggleButton = styled.div`
  display: none;
  border-right: 1px solid ${(props) => props.theme.dividerColor};
  font-size: 24px;
  line-height: 24px;
  height: 100%;
  padding: 0 4px;
  color: ${(props) => props.theme.gray.shade0};
  align-items: center;
  justify-content: center;
  cursor: pointer;

  i {
    margin-right: 0;
  }

  @media only screen and (max-width: ${deviceBreakpoints.mLaptop}px) {
    display: flex;
  }
`;

export const LeftHeader = styled.div`
  display: grid;
  margin-left: 20px;
  line-height: ${(props) => props.theme.default?.top_bar_font_line_height};
  ${(props) => dynamicStyles(props.theme.resolutions, 'top_bar_font_line_height', 'line-height')};

  @media only screen and (max-width: ${deviceBreakpoints.mLaptop}px) {
    display: none;
  }
`;

export const Para = styled.div`
  display: inline;
  font-size: ${(props) => props.theme.default?.top_bar_font};
  ${(props) => dynamicStyles(props.theme.resolutions, 'top_bar_font', 'font-size')};
  a {
    color: ${(props) => props.theme.primaryDarkColor};
    font-weight: bold;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const ChangelogLink = styled(Link)`
  color: ${(props) => props.theme.primaryDarkColor};
  font-weight: bold;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export const Text = styled.span`
  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const ButtonSC = styled(Button)`
  &&& {
    display: inline-block;
    padding: 6px 8px;
    margin-left: 10px;
  }
`;

export const RightHeader = styled.span`
  display: flex;
  justify-content: flex-end;
  width: ${(props) => (props.header ? 'auto' : '100%')};
  height: ${(props) => props.theme.default?.top_bar_height};
  ${(props) => dynamicStyles(props.theme.resolutions, 'top_bar_height', 'height')};
`;

export const LanguageSwitcher = styled.div`
  display: flex;
  margin: 0 1em;
  align-items: center;
`;

export const TestimonialContainer = styled.a`
  display: grid;
  gap: 5px;
  justify-items: center;
  align-content: center;
  padding: 0 20px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  @media only screen and (max-width: ${deviceBreakpoints.phone}px) {
    justify-items: start;
    padding: 0 10px;
  }
`;

export const Stars = styled.img`
  height: 32px;

  @media only screen and (max-width: ${deviceBreakpoints.phone}px) {
    height: 26px;
  }
`;

export const TestimonialText = styled.div`
  display: flex;
  text-align: center;
`;

export const SocialMediaLink = styled.a`
  display: grid;
  justify-items: center;
  align-content: center;
  cursor: pointer;
  border-left: 1px solid #e6e6e6;
  width: 6.5em;
  &:hover {
    color: ${(props) => props.theme.gray.shade0};
  }
  div {
    font-size: ${(props) => props.theme.default?.top_bar_right_icon_font};
    ${(props) => dynamicStyles(props.theme.resolutions, 'top_bar_right_icon_font', 'font-size')};
    color: ${(props) => props.theme.gray.shade10};
    font-weight: bold;
  }
  i {
    font-size: ${(props) => props.theme.default?.top_bar_right_icon_width};
    width: ${(props) => props.theme.default?.top_bar_right_icon_width};
    height: ${(props) => props.theme.default?.top_bar_right_icon_height};
    ${(props) => dynamicStyles(props.theme.resolutions, 'top_bar_right_icon_width', 'font-size')};
    margin-bottom: 3px;
    color: ${(props) => props.theme.gray.shade10};
  }
  .signout-icon {
    margin-left: 2px;
  }
`;
