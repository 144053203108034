import React from 'react';
import Dialog from '@material-ui/core/Dialog';

const DialogBox = ({ children, open, onClose, width }) => {
  return (
    <Dialog
      PaperProps={{
        style: { borderRadius: '15px' },
      }}
      style={{ zIndex: 16000 }}
      maxWidth={width}
      open={open}
      onClose={onClose}
    >
      {children}
    </Dialog>
  );
};
export default DialogBox;
