/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-01-31 16:08:56
 * @modify date 2021-01-31 16:08:56
 */

import React from 'react';
import { BadgeSC } from './Badge.style';

export default function Badge({ number = 0 }) {
  return (
    <BadgeSC data-testid="badge" className="badge-number">
      {number}
    </BadgeSC>
  );
}
