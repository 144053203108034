import React from 'react';
import { useMediaQuery } from '@material-ui/core';
import SlidingPanels from 'components/common/sliding-panel';

const SettingsSlidingPanel = ({ children, openPanel, setOpenPanel }) => {
  const match = useMediaQuery('(max-width:550px)');

  return (
    <React.Fragment>
      <SlidingPanels size={match ? 93 : 72} isOpen={openPanel} isClose={setOpenPanel}>
        {children}
      </SlidingPanels>
    </React.Fragment>
  );
};

export { SettingsSlidingPanel };
