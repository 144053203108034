import React from 'react';
import * as Styles from './DomainLastStep.style';
import { Trans, useTranslation } from 'react-i18next';
import CustomButton from 'components/common/custom-button';
import { P } from 'components/reusable-css';
import { UpperCaseWord } from 'assets/css/common-styles';
import { useSelector } from 'react-redux';
import { navLinks } from 'utils/nav-links-util';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const DomainLastStep = ({ formikProp }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { isSubmitting, values, handleSubmit } = formikProp;
  const userAccount = useSelector((state) => state.user.account);
  const isError = values.new_domain == '';
  const domainCart = useSelector((state) => state.domainCart.domain_deploy);

  return (
    <Styles.DomainLastStepSC>
      <Styles.PriceServer>
        <Styles.Content>
          <P className="your_server_price">{t('domain/instance/create.string1')}</P>
          <Styles.Price>
            <Trans
              i18nKey="domain/instance/create.string2"
              values={{
                price: values.price,
                currency: userAccount.account_currency,
                currency_symbol: userAccount.account_currency_symbol,
              }}
              components={{
                price_per_year: <Styles.PerYear />,
                price_yearly: <Styles.PriceUnit />,
                uppercase: <UpperCaseWord />,
              }}
            />
          </Styles.Price>
        </Styles.Content>
      </Styles.PriceServer>
      <Styles.VirtualServer>
        <Styles.SubmitServer>
          <CustomButton
            title={t('domain/instance/create.string3')}
            isLoading={isSubmitting}
            type="submit"
            buttonStyles={{
              marginLeft: '10px',
              padding: '0.8rem 1rem',
              opacity: isError && 0.3,
              backgroundColor: isError && 'gray',
            }}
            onClick={() => {
              handleSubmit();
            }}
          />
          <CustomButton
            title={t('domain/instance/create.string4')}
            type="submit"
            disabled={domainCart.length == 0}
            buttonStyles={{ marginLeft: '10px', padding: '0.8rem 1rem' }}
            onClick={() => history.replace(navLinks.domain_instance_summary.link)}
          />
        </Styles.SubmitServer>
      </Styles.VirtualServer>
    </Styles.DomainLastStepSC>
  );
};

export default DomainLastStep;
