import React, { useState } from 'react';
import * as Styles from 'pages/member/domain-checkout/DomainCheckout.style';
import Checkbox from 'components/common/checkbox/Checkbox';
import { Trans, useTranslation } from 'react-i18next';
import { P } from 'components/reusable-css';
import { UpperCaseWord } from 'assets/css/common-styles';
import CustomButton from 'components/common/custom-button/custom-button';
import { useSelector } from 'react-redux';

const PlaceOrder = ({ selected, title, totalPrice }) => {
  const [isRememberPassword, setIsRememberPassword] = useState(true);
  const { t } = useTranslation();
  const userAccount = useSelector((state) => state.user.account);

  return (
    <>
      <Styles.PlaceOrder>
        <Checkbox
          link="https://www.serverpoint.com/tos.pdf"
          id="check"
          title="domain/instance/summary.string34"
          value={isRememberPassword}
          setValue={setIsRememberPassword}
          labelStyle={{
            paddingTop: '7px',
          }}
        />
      </Styles.PlaceOrder>
      <Styles.PlaceOrderContainer>
        <Styles.PriceServer>
          <Styles.Content>
            <P className="your_server_price">{t('domain/instance/summary.string36')}</P>
            <Styles.Price>
              <Trans
                i18nKey="domain/instance/summary.string47"
                values={{
                  price: totalPrice.toFixed(2),
                  currency: userAccount.account_currency,
                  currency_symbol: userAccount.account_currency_symbol,
                }}
                components={{
                  price_per_year: <Styles.PerYear />,
                  price_yearly: <Styles.PriceUnit />,
                  uppercase: <UpperCaseWord />,
                }}
              />
            </Styles.Price>
          </Styles.Content>
        </Styles.PriceServer>
        <Styles.VirtualServer>
          <Styles.SubmitServer>
            <CustomButton
              disabled={selected == 100}
              title={t(title)}
              type="submit"
              buttonStyles={{ padding: '0.5rem 2rem' }}
            />
          </Styles.SubmitServer>
        </Styles.VirtualServer>
      </Styles.PlaceOrderContainer>
    </>
  );
};

export default PlaceOrder;
