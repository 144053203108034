/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-02-02 10:42:11
 * @modify date 2021-02-02 10:42:11
 */

import React from 'react';
import { StepLabelSC, StepCount, StepHeading } from '../styles/StepLabel.style';

export const StepLabel = ({ headingFontWeight, id, stepCount = '1', children }) => {
  return (
    <StepLabelSC id={id}>
      <StepCount>{stepCount}</StepCount>
      <StepHeading headingFontWeight={headingFontWeight}>{children}</StepHeading>
    </StepLabelSC>
  );
};
