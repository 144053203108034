import React, { useEffect, useRef } from 'react';
import NewBM from './NewBM';
import { initialValues, validationSchema } from 'components/bm-deployment/constants/bm-formik';
import { Formik } from 'formik';
import * as Yup from 'yup';
import hooks from 'hooks';
import { paymentMethodValidation, formValidation } from 'validations';
import { useMutation } from 'react-query';
import { BM_CREATE } from 'components/bm-deployment/queries';
import { filterBmValuesForCreation } from 'components/bm-deployment/util';
import { CHARGE_SAVED_CARD, SAVE_CHARGE_CARD } from 'components/vm-deployment/queries/billing';
import { useSelector } from 'react-redux';
import { get } from 'lodash';

const NewServerContainer = () => {
  const formikRef = useRef();
  const {
    isBmCardCharge,
    mutationProps,
    isBmAmountAvailableToTotal,
    defaultCard,
    chargeCardParams,
    amountToCharge,
    handleBmCreationSuccess: handleCreationSuccess,
  } = hooks();
  const userAccount = useSelector((state) => state.user.account);
  const userUI = useSelector((state) => state.user.ui);
  const billingRefetch = useSelector((state) => state.user.userBillingAccountRefetch);
  const { mutate: mutateBmCreation, isLoading: bmCreationLoad } = useMutation(BM_CREATE);
  const { mutate: chargeSavedCard, isLoading: chargeSavedCardLoad } = useMutation(CHARGE_SAVED_CARD);
  const { mutate: saveAndChargeCard, isLoading: saveAndChargeCardLoad } = useMutation(SAVE_CHARGE_CARD);
  const validation = Yup.lazy((values) => {
    let valid = {};
    let isCard = true;
    isCard = isBmCardCharge(values);
    if (isCard) {
      valid = paymentMethodValidation;
    }
    if (get(values, 'payment_method') === 'credit_debit') {
      if (!defaultCard) {
        valid = { ...valid, ...formValidation };
      }
    }
    return validationSchema(valid);
  });

  const bmCreation = async (values, { onSuccess } = {}) => {
    await mutateBmCreation(filterBmValuesForCreation(values, false), {
      isSuccess: false,
      onSuccess: () => {
        if (onSuccess) {
          onSuccess();
        }
        handleCreationSuccess();
      },
    });
  };
  const bmCreationProps = (values, props) => {
    return mutationProps('', {
      isSuccess: false,
      onSuccess: async () => {
        await bmCreation(values, props);
      },
    });
  };
  const handleSubmit = (values) => {
    const amount = amountToCharge(values);
    mutateBmCreation(
      filterBmValuesForCreation(values, true),
      mutationProps('', {
        isSuccess: false,
        onSuccess: () => {
          let isCharge = true;
          if (get(values, 'options_prepaid.id') == 1) {
            isCharge = !isBmAmountAvailableToTotal(values);
          }
          if (isCharge && get(values, 'payment_method') === 'credit_debit') {
            if (defaultCard) {
              chargeSavedCard(
                {
                  amount,
                  currency: userAccount.account_currency,
                },
                bmCreationProps(values),
              );
            } else {
              saveAndChargeCard(
                chargeCardParams(values, amount),
                bmCreationProps(values, { onSuccess: billingRefetch }),
              );
            }
          } else {
            bmCreation(values);
          }
        },
      }),
    );
  };

  useEffect(() => {
    if (formikRef.current) {
      formikRef.current.setFieldTouched('virtual_server_name');
    }
  }, []);

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues(userUI)}
      validationSchema={validation}
      onSubmit={handleSubmit}
    >
      {(formikProp) => (
        <NewBM
          formikProp={formikProp || {}}
          loading={saveAndChargeCardLoad || chargeSavedCardLoad || bmCreationLoad}
        />
      )}
    </Formik>
  );
};

export default NewServerContainer;
