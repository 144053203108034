import React, { useMemo, useState, useCallback, useEffect } from 'react';
import * as Styles from 'pages/member/manage-vm/ManageVM.style';
import { Icon } from 'semantic-ui-react';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import CustomButton from 'components/common/custom-button';
import { getCountryImage, checkDeletedItems } from 'components/manage-vm/utils';
import { Dropdowns } from 'components/manage-vm';
import { Trans, useTranslation } from 'react-i18next';
import { WINDOWS } from 'components/vm-deployment/constants';
import Tooltips from 'components/Tooltip';
import sandAnimation from 'assets/gifs/sand-animation.gif';
import { VmNetworks, ConsoleModal, SettingsSlidingPanel } from './components';
import { BmNetworks } from 'components/manage-bm';
import ConditionalFragment from 'components/conditional-fragment';
import { BmHooks } from 'hooks';
import SettingPanel from './settings-panel';
import { capitalizeFirstLetter } from 'utils';
import { REBOOT, settingTabs, statisticPanelMenus, VS_INSTANCE_STATUS } from 'constants/index';
import { get } from 'lodash';
import StatisticsPanel from './statistics-panel';
import { EditLabel, ServerImage } from 'components/manage-vm/settings-panel/components';

const ManageVmCard = ({
  data,
  managerVmRefetch, // REFRESH '/VS/INSTANCE PAGE'
  allTags,
  managerVmIsFetching,
  backup,
  iconKeyBoard,
  status,
  network: type,
  isTags,
  dropdownItems,
  menuOptions,
  RefreshServer,
  handleConsoleOpen,
  deletedIds,
  setDeletedIds,
  serverActionsLoading,
  activeActive,
  opacity,
  serverStatus,
  vmStatusRefetch,
  serverIpDetails,
  rights,
  refetchTags,
}) => {
  const { t } = useTranslation();
  const { handleBackupHours } = BmHooks();
  const [openTag, setOpenTag] = useState(false);
  const statusOfServer = useMemo(
    () => get(serverStatus, VS_INSTANCE_STATUS, [])?.find((item) => item.id === data.id) || data,
    [serverStatus],
  );
  const [openConfigurationPanel, setOpenConfigurationPanel] = useState(false);
  const [openStatisticPanel, setOpenStatisticPanel] = useState(false);
  const [initialActionTab, setInitialActionTab] = useState();
  const [initialTab, setInitialTab] = useState();
  const handleClose = () => {
    setOpenTag(false);
  };
  const handleOpen = () => {
    setOpenTag(true);
  };
  const toggle = () => setOpenConfigurationPanel((prev) => !prev);
  const toggleStats = () => setOpenStatisticPanel((prev) => !prev);
  const inProgress = useMemo(() => statusOfServer?.is_deployed === '0', [statusOfServer?.is_deployed]);
  let checkDisabledItem = useMemo(() => checkDeletedItems(deletedIds, data?.id), [deletedIds, data?.id]);
  const enableBackup = () => {
    setInitialTab(settingTabs['templates/backups']);
    setOpenConfigurationPanel(true);
  };
  const enableActionsDelete = () => {
    setInitialTab(settingTabs.actions);
    setOpenConfigurationPanel(true);
    setInitialActionTab('delete_bm');
  };

  const enableActionsCancel = () => {
    setInitialTab(settingTabs.actions);
    setOpenConfigurationPanel(true);
    setInitialActionTab('cancel_VM');
  };

  const enableActionsResetPassword = () => {
    setInitialTab(settingTabs.actions);
    setOpenConfigurationPanel(true);
    setInitialActionTab('new_password');
  };

  const escFunction = useCallback((event) => {
    if (event.key === 'Escape') {
      setOpenStatisticPanel(false);
      setOpenConfigurationPanel(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);

    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, []);

  return (
    <Styles.Card style={checkDisabledItem} key={data.id}>
      <Styles.CardContent>
        <Styles.StartContent>
          <Styles.Picture>
            <ServerImage
              inProgress={inProgress}
              status={statusOfServer}
              data={data}
              managerVmRefetch={managerVmRefetch}
              type={type}
              vmStatusRefetch={vmStatusRefetch}
            />
          </Styles.Picture>
          <Styles.Border />
          <Styles.Adding center={data.tags}>
            <EditLabel
              labelText={data.label}
              data={data}
              managerVmRefetch={managerVmRefetch}
              allTags={allTags}
              refetchTags={refetchTags}
              managerVmIsFetching={managerVmIsFetching}
              isTags={isTags}
              type={type}
              statusOfServer={statusOfServer}
              openTag={openTag}
              handleClose={handleClose}
              handleOpen={handleOpen}
              inProgress={inProgress}
              enableActionsDelete={enableActionsDelete}
              enableActionsCancel={enableActionsCancel}
            />
          </Styles.Adding>
        </Styles.StartContent>
        <Styles.Country inProgress={inProgress}>
          <Styles.CountryFlag justifyContentStart={type === 'bm'}>
            <Styles.Circle style={{ border: '0.5px solid #C5C5C5' }}>
              <img src={getCountryImage(data?.region_country, data?.region_ucase)} alt="country" />
            </Styles.Circle>
            <h6 style={{ width: '50px' }}>{data.region_ucase}</h6>
            {status && (
              <React.Fragment>
                <Styles.Status managerVmIsFetching={vmStatusRefetch} status={statusOfServer?.status}>
                  {statusOfServer?.status ? t(capitalizeFirstLetter(statusOfServer?.status)) : t('vs/instance.string29')}
                </Styles.Status>
              </React.Fragment>
            )}
          </Styles.CountryFlag>
          {!inProgress && (
            <React.Fragment>
              {type === 'bm' ? (
                <BmNetworks data={data} />
              ) : (
                <VmNetworks data={data} serverIpDetails={serverIpDetails} />
              )}
            </React.Fragment>
          )}
        </Styles.Country>
        <ConditionalFragment condition={type === 'bm' && !inProgress}>
          <Styles.SerialNo>
            <p>
              <Trans i18nKey={'bm/instance.string3'} values={{ data: data.sn }}>
                <span>{data.sn}</span>
              </Trans>
            </p>
          </Styles.SerialNo>
        </ConditionalFragment>
        <Styles.Backup>
          <ConditionalFragment condition={!inProgress && backup}>
            <ConditionalFragment condition={data.backups_is_enabled == 0 && data?.billing_schedule == 'hourly'}>
              <CustomButton
                pointerEvents={checkDisabledItem}
                title={t('vs/instance.string22')}
                buttonStyles={{ padding: ' 9px 7px', fontSize: '12px' }}
                onClick={enableBackup}
              />
            </ConditionalFragment>
            <ConditionalFragment condition={data.backups_is_enabled == 1}>
              <Trans i18nKey={handleBackupHours(data).text} values={{ data: handleBackupHours(data).param }}>
                <p />
                <span />
              </Trans>
            </ConditionalFragment>
          </ConditionalFragment>
        </Styles.Backup>
        <React.Fragment>
          <ConditionalFragment condition={inProgress}>
            <Styles.SettingIcon>
              <div style={{ width: '264px' }}>
                <img src={sandAnimation} />
                {statusOfServer.os_family === WINDOWS ? t('vs/instance.string24') : t('vs/instance.string25')}
              </div>
            </Styles.SettingIcon>
          </ConditionalFragment>
          <ConditionalFragment condition={!inProgress}>
            <Styles.SettingIcon
              loading={serverActionsLoading && activeActive.id === data.id && activeActive.action === REBOOT}
            >
              <ConditionalFragment condition={rights?.vsreboot}>
                <Tooltips title={t('vs/instance.string26')}>
                  <Icon
                    size="large"
                    name="refresh"
                    onClick={async () => {
                      await RefreshServer(REBOOT);
                    }}
                  />
                </Tooltips>
              </ConditionalFragment>
              {iconKeyBoard && <ConsoleModal handleConsoleOpen={handleConsoleOpen} />}
              <Tooltips title={t('vs/instance.string27')}>
                <EqualizerIcon
                  style={{ fontSize: 28 }}
                  onClick={() => {
                    setOpenStatisticPanel(true);
                  }}
                />
              </Tooltips>
              <Icon
                size="large"
                name="setting"
                onClick={async () => {
                  managerVmRefetch();
                  setOpenConfigurationPanel(true);
                  setInitialTab('');
                }}
              />
            </Styles.SettingIcon>
          </ConditionalFragment>
        </React.Fragment>
        <Styles.More>
          {!inProgress && (
            <Dropdowns
              dropdownItems={dropdownItems}
              setOpenConfigurationPanel={setOpenConfigurationPanel}
              RefreshServer={RefreshServer}
              handleConsoleOpen={handleConsoleOpen}
              setId={setDeletedIds}
              id={data.id}
              deletedIds={deletedIds}
              opacity={opacity}
              enableBackup={enableBackup}
              inProgress={inProgress}
              toggleStats={toggleStats}
              upgradeOpen={() => {
                managerVmRefetch();
                setOpenConfigurationPanel(true);
                setInitialTab('');
              }}
              resetPasswordOpen={enableActionsResetPassword}
              data={data}
              handleActionsOpen={() => {
                managerVmRefetch();
                setInitialTab('actions');
                setOpenConfigurationPanel(true);
                setInitialActionTab('cancel_VM');
              }}
            />
          )}
        </Styles.More>
      </Styles.CardContent>
      <SettingsSlidingPanel openPanel={openConfigurationPanel} setOpenPanel={setOpenConfigurationPanel}>
        {openConfigurationPanel && (
          <SettingPanel
            data={data}
            type={type}
            menuOptions={menuOptions}
            openConfigurationPanel={openConfigurationPanel}
            setOpenConfigurationPanel={setOpenConfigurationPanel}
            initialTab={initialTab}
            setDeletedIds={() => setDeletedIds((prev) => [...prev, data.id])}
            toggle={toggle}
            managerVmRefetch={managerVmRefetch}
            allTags={allTags}
            isTags={isTags}
            statusOfServer={statusOfServer}
            managerVmIsFetching={managerVmIsFetching}
            initialActionTab={initialActionTab}
          />
        )}
      </SettingsSlidingPanel>
      <SettingsSlidingPanel openPanel={openStatisticPanel} setOpenPanel={setOpenStatisticPanel} isMobile>
        {openStatisticPanel && (
          <StatisticsPanel
            openStatisticPanel={openStatisticPanel}
            setOpenStatisticPanel={setOpenStatisticPanel}
            data={data}
            menuOptions={statisticPanelMenus}
            type={type}
          />
        )}
      </SettingsSlidingPanel>
    </Styles.Card>
  );
};

export const ManageVMCard = React.memo(ManageVmCard);
