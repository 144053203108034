import { pStyles } from 'components/reusable-css';
import styled from 'styled-components';
import { variables } from 'variables';

export const NewDomainStep2SC = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  width: 100%;
  padding: 4em ${variables.pageContentPadding}px;
`;

export const StepDescription = styled.div`
  margin-top: 50px;
  margin-bottom: 1.5em;
  font-size: ${(props) => props.theme.default?.p};
  ${(props) => pStyles(props.theme.resolutions)};
`;
