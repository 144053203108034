import * as Yup from 'yup';

export const initialValues = {
  new_domain: '',
  price: 0,
  privacy: 'privacy_protection',
  renew: 'auto_renew',
  term: 1,
};

export const validationSchema = () =>
  Yup.object({
    new_domain: Yup.string().required('Domain required'),
  });

export const initialValuesContactDetail = {
  new_domain: '',
  price: 100,
};

export const validationSchemaContactDetail = () =>
  Yup.object({
    new_domain: Yup.string().required('Domain required'),
  });

export const cityData = [
  {
    name: 'city',
    placeholder: 'Your city.',
    placeholder_lang: 'domain/instance/summary.string21',
  },
  {
    name: 'state',
    placeholder: 'State/Province.',
    placeholder_lang: 'domain/instance/summary.string22',
  },
  {
    name: 'zip',
    placeholder: 'Zip Code.',
    placeholder_lang: 'domain/instance/summary.string23',
  },
];

export const nameData = [
  {
    type: 'text',
    name: 'first_name',
    placeholder_lang: 'domain/instance/summary.string16',
  },
  {
    type: 'text',
    name: 'last_name',
    placeholder_lang: 'domain/instance/summary.string17',
  },
];
