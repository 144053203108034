import React, { useState } from 'react';
import * as Styles from 'components/manage-vm/settings-panel/SettingsPanel.style';
import Input from 'components/input';
import CustomButton from 'components/common/custom-button';
import { Grid, useMediaQuery } from '@material-ui/core';
import { BackupTop } from './index';
import { useMutation } from 'react-query';
import { DELETE_PERMANENT_BACKUP, PERMANENT_BACKUP } from 'components/manage-vm/queries';
import hooks from 'hooks';
import { LABEL_SCHEMA } from 'constants/yup';
import { save_backup } from 'constants/index';
import { useTranslation } from 'react-i18next';

const PermanentBackup = ({ backupData, data, backupLevelRefetch, backupInstanceRefetch, managerVmRefetch }) => {
  const { mutationProps } = hooks();
  const { t } = useTranslation();
  const [value, setValue] = useState('');
  const [isValid, setIsValid] = useState(true);
  const match = useMediaQuery('(min-width:960px)');
  const match1 = useMediaQuery('(max-width:959px)');
  let buttonStyle = match
    ? { marginTop: '13px', padding: '13px' }
    : match1
    ? { marginTop: '4px', padding: '13px' }
    : null;
  const { mutate: backupDelete, isLoading: backupDeleteLoad } = useMutation(DELETE_PERMANENT_BACKUP, {
    ...mutationProps('', {
      error: 'error',
      onSuccess: async () => {
        await backupLevelRefetch();
        await backupInstanceRefetch();
        await managerVmRefetch();
      },
    }),
  });
  const { mutate: createBackup, isLoading: createBackupLoad } = useMutation(PERMANENT_BACKUP, {
    ...mutationProps('', {
      error: 'error',
      onSuccess: async () => {
        await backupLevelRefetch();
        await backupInstanceRefetch();
        await managerVmRefetch();
      },
    }),
  });
  const validLabel = async (value) => {
    const isValid = await LABEL_SCHEMA.isValid({
      label: value,
    });
    setIsValid(isValid);
    return isValid;
  };
  const handleCreateBackup = async () => {
    const valid = await validLabel(value);
    if (valid) await createBackup({ action: save_backup, label: value, id: data.id });
  };
  return (
    <Styles.PermanentBackup>
      <BackupTop
        backupData={backupData}
        type="vs_archive_backups"
        titleText="vs/instance.string145"
        currentInfo="vs/instance.string148"
        subtitle="vs/instance.string149"
        emptyMessage="vs/instance.string150"
        isPermanent
        backupDelete={(backup) => backupDelete({ id: data.id, backup })}
        backupDeleteLoad={backupDeleteLoad}
        data={data}
      />
      <Styles.Align>
        <Styles.BackupPadding>
          <Styles.InputName>
            <Grid container spacing={0}>
              <Grid xs={12} sm={12} md={8} lg={6} xl={5}>
                <Input
                  styling={{ marginTop: '-15px', marginRight: '10px' }}
                  type={'text'}
                  name="permanent_backup_name"
                  value={value}
                  placeholder={t('vs/instance.string164')}
                  onChange={async (e) => {
                    setValue(e.target.value);
                    await validLabel(e.target.value);
                  }}
                  isFormIk={false}
                  error={!isValid && 'Not in correct format'}
                  touched
                  loading={createBackupLoad}
                  onEnter={handleCreateBackup}
                  dataTooltip={t('vs/instance.string167')}
                />
              </Grid>
              <Grid>
                <CustomButton
                  title={'vs/instance.string165'}
                  buttonStyles={buttonStyle}
                  isLoading={createBackupLoad}
                  onClick={handleCreateBackup}
                />
              </Grid>
            </Grid>
          </Styles.InputName>
        </Styles.BackupPadding>
      </Styles.Align>
    </Styles.PermanentBackup>
  );
};
export { PermanentBackup };
