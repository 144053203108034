/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-02-02 10:46:06
 * @modify date 2021-02-02 10:46:06
 */

import React from 'react';
import { HighlightSC } from '../styles/Highlight.style';

/** Highlight component highlights the string in primary color */
export const Highlight = ({ children = '', spaceLeft = false, spaceRight = false, color }) => {
  return (
    <HighlightSC style={color && { color }} data-testid="highlight">
      {spaceLeft ? <span>&nbsp;</span> : ''}
      {children}
      {spaceRight ? <span>&nbsp;</span> : ''}
    </HighlightSC>
  );
};
