import React, { useEffect, useState } from 'react';
import * as Styles from 'components/manage-vm/settings-panel/SettingsPanel.style';
import { useTranslation, Trans } from 'react-i18next';
import { Highlight, StepLabel, Notification } from 'components/vm-deployment/components';
import ServerSizeCard from 'components/vm-deployment/server-size-card';
import { Icon } from 'semantic-ui-react';
import ConditionalFragment from 'components/conditional-fragment';
import ServerSizeSlider from 'components/vm-deployment/server-size-slider';
import hooks from 'hooks';
import { Message } from 'components/alert/Message';
import FormikErrorMessage from 'components/formik-error-message';
import { get } from 'lodash';
import { Features, Step3, UpgradeLastStep } from './components';
import { getUpdatedFeatures, isSliderBaseUpgradeDefaultForFeatures } from 'components/manage-vm/utils';
import { useQuery } from 'react-query';
import { H4, H5, P } from 'components/reusable-css';
import Checkbox from 'components/common/radio-button/RadioButton';
import { vsCategory } from 'components/vm-deployment/constants';
import { useSelector } from 'react-redux';
import { useBackupPrice } from 'hooks/backup-price';

const Upgrade = ({ serverSizes, sliderData, sliderSuccess, sliderSizeData, formikProp, data, loading }) => {
  const { t } = useTranslation();
  const [categoryID, setCategoryID] = useState('general');
  const { values, setFieldValue } = formikProp;
  const { isSliderInterface } = hooks();
  const { backupData } = useBackupPrice();
  const { data: OsDataPrice } = useQuery(isSliderInterface ? '/prices/vs/custom_os' : '/prices/vs/os');
  const osPrice = get(OsDataPrice, 'prices_vs_os', []).find((item) => item.id === data.os);
  const isSliderUpgraded = isSliderBaseUpgradeDefaultForFeatures(data, formikProp.values);
  const isSizeUpgraded = formikProp.values.size;
  const isUpgraded = isSliderInterface ? !isSliderUpgraded : isSizeUpgraded;
  const features = getUpdatedFeatures(data, { isUpgraded, backupData: backupData(data), formikProp, osPrice });
  const sizeGreyOutCondition = (serverSize) => data.disks.some((item) => +item.disk_gb > +serverSize.disk_gb);

  //CHECK FOR AVAILABLE HIGH FREQUENCY VIRTUAL SERVERS IN THE REGION
  const isRegionIsAvailable = (serverSize) => serverSize.availableregions.includes(data.region);

  const handleServerSizeSelection = (data) => {
    //IF HIGH FREQUENCY VIRTUAL SERVER IS NOT AVAILABLE THEN SHOW BELOW ERROR
    if (!isRegionIsAvailable(data)) {
      Message.error(t('apierror_plan_not_available_in_this_region'));
    } else if (!sizeGreyOutCondition(data)) {
      setFieldValue('prices.size', data.price_monthly_usd);
      setFieldValue('size', data);
    } else {
      Message.error(t('alert18'), t('alert19'));
    }
  };
  const isSaleAvailable = serverSizes.some(
    (data) => Number(data.price_monthly_usd) - Number(data.nonsale_price_monthly_usd),
  );
  const user = useSelector((state) => state.user.account);

  const currentPrice = {
    mo: +data.price_monthly_usd,
    hourly: +data.price_hourly_usd,
  };
  useEffect(() => {
    setFieldValue('nic_mbps', data.nic_mbps);
    const defaultValue = serverSizes.find((item) => item?.id === user?.vs_last_size_selected);
    if (defaultValue) {
      setCategoryID(defaultValue.category);
    }
  }, [data]);

  const handleClick = (val) => setCategoryID(val.id);

  return (
    <>
      <Styles.Column>
        <Styles.Background>
          <Styles.Padding>
            <StepLabel stepCount={t('vs/instance.string75')}>
              <ConditionalFragment condition={isSliderInterface}>
                <Trans i18nKey="vs/instance.string76" components={{ highlight: <Highlight /> }} />
              </ConditionalFragment>
              <ConditionalFragment condition={!isSliderInterface}>
                <Trans i18nKey="vs/instance.string77" components={{ highlight: <Highlight /> }} />
              </ConditionalFragment>
            </StepLabel>
          </Styles.Padding>
          <ConditionalFragment condition={isSliderInterface}>
            <div className="pb-4" />
          </ConditionalFragment>
          <ConditionalFragment condition={!isSliderInterface}>
            <Styles.Padding>
              <P>
                <Trans i18nKey={'vs/instance.string78'} values={{ size: data.size.toUpperCase() }} />
              </P>
            </Styles.Padding>
          </ConditionalFragment>
          {!isSliderInterface && (
            <Styles.Padding>
              <H4>{t('vs/instance.string79')}</H4>
              <Styles.CardContainer>
                {vsCategory.map((category, i) => (
                  <Styles.CardContainers key={i}>
                    <Styles.Card selected={category.id == categoryID} onClick={() => handleClick(category)}>
                      <Checkbox
                        checkbox={categoryID}
                        setCheckedValue={(value) => setCategoryID(value)}
                        data={category}
                        onClick={() => handleClick(category)}
                      />
                      <H5>{t(category.lang)}</H5>
                    </Styles.Card>
                    <Styles.TriangleLine selected={category.id == categoryID}>
                      <div className="line" />
                      <div className="doubleline" />
                    </Styles.TriangleLine>
                  </Styles.CardContainers>
                ))}
              </Styles.CardContainer>
              <Styles.Line categoryID={categoryID}>
                <div className="line" />
                <div className="doubleline" />
              </Styles.Line>
            </Styles.Padding>
          )}
          <div id="ST1">
            <ConditionalFragment condition={isSliderInterface}>
              <div className="ml-5 mt-5">
                <ServerSizeSlider
                  formikProp={formikProp}
                  sliderData={sliderData}
                  sliderSuccess={sliderSuccess}
                  sliderSizeData={get(sliderSizeData, 'vs_slider_units', [])}
                  isPriceFormik
                  formIkPrice="prices"
                  defaultData={data}
                />
              </div>
            </ConditionalFragment>
            <ConditionalFragment condition={!isSliderInterface}>
              <Styles.ServerSizeGrid>
                {(serverSizes || []).map((serverSize) => (
                  <>
                    {serverSize.active && serverSize.category == categoryID && (
                      <ServerSizeCard
                        key={serverSize.id}
                        data={serverSize}
                        selected={serverSize?.id === values.size?.id}
                        onClick={handleServerSizeSelection}
                        formikProp={formikProp}
                        greyOutCondition={sizeGreyOutCondition(serverSize) || !isRegionIsAvailable(serverSize)}
                        watermark={serverSize?.id === data.size && 'vs/instance.string84'}
                        selectedText="vs/instance.string85"
                        userAccount={user}
                      />
                    )}
                  </>
                ))}
              </Styles.ServerSizeGrid>
            </ConditionalFragment>
            <Styles.Padding style={{ paddingTop: '0', paddingBottom: '0' }}>
              {isSaleAvailable && (
                <Styles.NotificationContainer>
                  <Notification>
                    <Icon name="info circle" />
                    {t('vs/instance.string86')}
                  </Notification>
                </Styles.NotificationContainer>
              )}
            </Styles.Padding>
            <ConditionalFragment condition={!isSliderInterface}>
              <FormikErrorMessage value="size" innerValue="id" formikProp={formikProp} />
            </ConditionalFragment>
          </div>
          <ConditionalFragment condition={isSliderInterface}>
            <Step3 sliderData={sliderData} formikProp={formikProp} />
          </ConditionalFragment>
          <Styles.OrangeBorder />
          <Features features={features} isSliderInterface={isSliderInterface} />
        </Styles.Background>
      </Styles.Column>
      <div style={{ marginTop: 'auto' }}>
        <Styles.OrangeBorder />
        <UpgradeLastStep
          data={data}
          features={features}
          formikProp={formikProp}
          loading={loading}
          currentPrice={currentPrice}
        />
      </div>
    </>
  );
};

export default React.memo(Upgrade);
