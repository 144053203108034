import React from 'react';
import * as Styles from 'pages/member/manage-vm/ManageVM.style';
import Tooltips from 'components/Tooltip';
import { Icon } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { P } from 'components/reusable-css';

const Network = ({ ip }) => {
  const { t } = useTranslation();
  return (
    <div>
      <Styles.Flexx key={ip.ip}>
        {ip.type == 'primary' ? (
          <Tooltips title={t('vs/instance.string168')}>
            <Styles.Flexx style={{ width: '115px' }}>
              <P>{ip.ip}</P>
              <Icon name="check" />
            </Styles.Flexx>
          </Tooltips>
        ) : (
          <P>{ip.ip}</P>
        )}
      </Styles.Flexx>
    </div>
  );
};
export { Network };
