import React, { useState } from 'react';
import ManageVM from 'pages/member/manage-vm/ManageVM';
import { useQuery } from 'react-query';
import Loading from 'components/common/loading';
import { useSelector } from 'react-redux';
import { managerVmSortBy, menuOptionsBm } from 'constants/manager-vm';
import { get } from 'lodash';
import { MANAGE_BM } from 'components/manage-bm/queries';
import { bmDropDownItems } from 'components/manage-vm/utils';
import hooks from 'hooks';
import { navLinks } from 'utils/nav-links-util';
import { userRights } from 'store/selectors/user-details';

const ManageBm = () => {
  const { getParams, per_page, paginationToUrl } = hooks();
  const userUI = useSelector((state) => state.user.ui);
  const rights = useSelector(userRights);
  const userUIRefetch = useSelector((state) => state.user.userUiRefetch);
  const { page_number } = getParams;
  const sort_by = getParams.sort_by || managerVmSortBy[0].value;
  const [queries, setQueries] = useState({
    page_number: page_number || 1,
    per_page,
    sort_by,
  });
  const instance = 'bm_instance';
  const {
    data: vmData,
    isLoading,
    refetch: managerVmRefetch,
    isFetching: managerVmIsFetching,
  } = useQuery(['MANAGE_BM', queries], MANAGE_BM, {
    refetchInterval: Number(userUI.refresh_every_x_seconds) * 1000,
    onSuccess: (res) => {
      const isDeploymentInProcess = get(vmData, instance, []).some(
        (data) => data.date_deployment_completed === '0000-00-00 00:00:00',
      );
      if (userUIRefetch && isDeploymentInProcess) userUIRefetch();
      paginationToUrl(navLinks.manage_your_servers.link, +res.meta.page_number, queries.sort_by, +res?.meta?.per_page);
    },
  });

  const { data: preferenceData, refetch: preferenceDataRefetch } = useQuery(`/preferences`);
  if (isLoading) {
    return <Loading gap={200} />;
  }
  return (
    <ManageVM
      vmData={vmData || {}}
      setQueries={setQueries}
      queries={queries}
      managerVmRefetch={managerVmRefetch}
      managerVmIsFetching={managerVmIsFetching}
      backup={false}
      iconKeyBoard={false}
      status={false}
      instance={instance}
      network="bm"
      isFilter={false}
      // isSort={false}
      // pagination={false}
      isTags
      dropdownItems={bmDropDownItems(rights)}
      menuOptions={menuOptionsBm}
      preferenceData={preferenceData}
      preferenceDataRefetch={preferenceDataRefetch}
    />
  );
};

export default ManageBm;
