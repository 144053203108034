import React, { useEffect, useState } from 'react';
import { NewVMSC } from './NewVM.style';
import NewVMStep1Container from 'pages/member/new-vm/new-vm-step-1/NewVMStep1.container';
import NewVMStep2Container from 'pages/member/new-vm/new-vm-step-2/NewVMStep2.container';
import NewVMStep3Container from 'pages/member/new-vm/new-vm-step-3/NewVMStep3.container';
import NewVmStep4Container from 'pages/member/new-vm/new-vm-step-4/NewVMStep4.container';
import NewVMPageContainer from 'pages/member/new-vm/new-vm-page/NewVMPage.container';
import NewVMStep5Container from 'pages/member/new-vm/new-vm-step-5/NewVMStep5.container';
import NewVMStep6Container from 'pages/member/new-vm/new-vm-step-6/NewVMStep6.container';
import NewVMStep7Container from 'pages/member/new-vm/new-vm-step-7/NewVMStep7.container';
import Loading from 'components/common/loading';
import { useQuery } from 'react-query';
import _get from 'lodash/get';
import { sizePricingFilter } from 'utils/vm-deployment';
import { useDispatch, useSelector } from 'react-redux';
import { updateNoOfVM, updatePricing, updateVM_Deployment } from 'store/actions/vm-deployment';
import PriceCalculator from 'components/vm-deployment/price-calculator';
import NewVMLastStepContainer from './new-vm-last-step/NewVMLastStep.container';
import VisibilitySensor from 'react-visibility-sensor';
import classes from 'assets/css/fading-animation.module.css';
import priceCalculator from 'utils/price-calculator';
import { totalPrice } from 'components/vm-deployment/util';
import { defaultPricingDetailValues } from 'components/vm-deployment/constants';
import VmUtils from './util';

export default function NewVM({ formikProp }) {
  const { setFieldValue } = formikProp;
  const dispatch = useDispatch();
  const [categoryID, setCategoryID] = useState('general');
  const { isSliderInterface } = VmUtils();
  const [selectedServerSize, setSelectedServerSize] = useState({});
  const [isShowPriceButton, setIsShowPriceButton] = useState(true);
  const user = useSelector((state) => state.user.account);
  const noOfVM = useSelector((state) => state.vmDeployment.noOfVM);
  const price = useSelector((state) => state.vmDeployment.pricingDetails);

  const { data: OsData, isLoading: osDataLoad } = useQuery('/vs/os');
  const { data: OsDataPrice, isLoading: osPriceLoad } = useQuery(
    isSliderInterface ? '/prices/vs/custom_os' : '/prices/vs/os',
  );
  const { data: vsTemplate, isError: templateError, isLoading: templateLoading } = useQuery('/vs/savedtemplates');
  const {
    data: sliderData,
    isLoading: sliderLoading,
    isSuccess: sliderSuccess,
  } = useQuery('/prices/vs/sliders', {
    enabled: isSliderInterface,
  });
  const { data: sliderSizeData, isLoading: sliderSizeLoading } = useQuery('/vs/slidersizes', {
    enabled: isSliderInterface,
  });
  const { data: sizeDataPrice, isLoading: sizePriceLoading } = useQuery('/prices/vs/sizes', {
    enabled: !isSliderInterface,
  });
  const { data: sizeData, isLoading: sizeLoading } = useQuery('/vs/sizes', {
    enabled: !isSliderInterface,
  });
  const setValues = (defaultValue) => {
    setFieldValue('size', defaultValue.id);
    dispatch(updateVM_Deployment({ server_size: defaultValue }));
    const price = _get(sizeDataPrice, 'prices_vs_sizes', []).find((item) => item.id === defaultValue.id);
    dispatch(updatePricing(_get(price, 'price_monthly_usd', 0), 'size'));
    setSelectedServerSize(defaultValue);
  };
  const handleDefaultValues = () => {
    if (!isSliderInterface) {
      const defaultValue = _get(sizeData, 'vs_sizes', []).find((item) => item?.id === user?.vs_last_size_selected);
      if (defaultValue) {
        setCategoryID(defaultValue.category);
        return setValues(defaultValue);
      }
      const mostPopularSizeServer = _get(sizeData, 'vs_sizes', []).find((size) => Number(size.mostpopular));
      if (mostPopularSizeServer) {
        setValues(mostPopularSizeServer);
      } else {
        setValues(_get(sizeData, 'vs_sizes[0]', {}));
      }
    }
  };
  const isLoading =
    sizeLoading ||
    sizePriceLoading ||
    sliderLoading ||
    sliderSizeLoading ||
    osPriceLoad ||
    osDataLoad ||
    templateLoading;
  useEffect(() => {
    if (!isSliderInterface) {
      Object.keys(defaultPricingDetailValues).forEach((item) => {
        dispatch(updatePricing(0, item));
      });
      handleDefaultValues();
    }
    dispatch(updateNoOfVM(1));
  }, [sizeData, sizeDataPrice]);

  if (isLoading) {
    return <Loading gap={200} />;
  }
  const serverSizes = sizePricingFilter(sizeData, sizeDataPrice);

  return (
    <NewVMSC>
      {!isLoading && (
        <div className={isShowPriceButton ? classes.price_box_show : classes.price_box_hide}>
          <PriceCalculator totalPrice={priceCalculator(totalPrice(price) * noOfVM)} />
        </div>
      )}
      <NewVMStep1Container
        selectedServerSize={selectedServerSize}
        setSelectedServerSize={setSelectedServerSize}
        serverSizes={serverSizes}
        formikProp={formikProp || {}}
        sliderData={sliderData}
        OsData={OsData || {}}
        sliderSuccess={sliderSuccess}
        sliderSizeData={_get(sliderSizeData, 'vs_slider_units', [])}
        isShowPriceButton={isShowPriceButton}
        vsTemplate={vsTemplate || {}}
        categoryID={categoryID}
        setCategoryID={setCategoryID}
      />
      <NewVMStep2Container formikProp={formikProp || {}} categoryID={categoryID} sizeData={sizeData} />
      <NewVMStep3Container
        formikProp={formikProp || {}}
        updatePriceCondition={sizeData || sizeDataPrice}
        OsDataPrice={OsDataPrice || {}}
        OsData={OsData || {}}
        templateError={templateError}
        vsTemplate={vsTemplate || {}}
        serverSizes={serverSizes}
        setValues={setValues}
      />
      <NewVmStep4Container formikProp={formikProp || {}} vsTemplate={vsTemplate || {}} />
      <NewVMPageContainer />
      <NewVMStep5Container formikProp={formikProp} />
      <NewVMStep6Container formikProp={formikProp || {}} />
      <NewVMStep7Container formikProp={formikProp || {}} />
      <VisibilitySensor
        partialVisibility
        onChange={(isVisible) => {
          setIsShowPriceButton(!isVisible);
        }}
      >
        <NewVMLastStepContainer formikProp={formikProp} />
      </VisibilitySensor>
    </NewVMSC>
  );
}
