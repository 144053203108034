import React from 'react';
import * as Styles from './DomainStep2.style';
import { useTranslation } from 'react-i18next';
import Heading from 'components/Heading/Heading';
import Checkbox from 'components/common/radio-button/RadioButton';
import { H6 } from 'components/reusable-css';
import { Grid } from '@material-ui/core';

const privacyData = [
  {
    title: 'domain/instance/create.step2_string2',
    id: 'privacy_protection',
  },
  {
    title: 'domain/instance/create.step2_string3',
    id: 'disable_privacy',
  },
];

const renewData = [
  {
    title: 'domain/instance/create.step2_string4',
    id: 'auto_renew',
  },
  {
    title: 'domain/instance/create.step2_string5',
    id: 'not_auto_renew',
  },
];

const DomainStep2 = ({ formikProp }) => {
  const { setFieldValue, values } = formikProp;
  const { t } = useTranslation();
  const handleValues = (key, value) => setFieldValue(key, value);

  return (
    <Styles.NewDomainStep2SC>
      <Heading name={`domain/instance/create.step2_string1`}>
        <Grid
          container
          style={{ marginTop: '3em', display: 'flex', flexWrap: 'wrap', alignItems: 'center', width: '100%' }}
        >
          <Grid xs={12} lg={5} xl={4} style={{ marginRight: '2em' }}>
            {privacyData.map((val, index) => (
              <div
                onClick={() => handleValues('privacy', val.id)}
                key={index}
                style={{ width: '100%', marginTop: '10px', display: 'flex', alignItems: 'center', cursor: 'pointer' }}
              >
                <Checkbox
                  checkbox={values.privacy}
                  setCheckedValue={(value) => handleValues('privacy', value)}
                  data={val}
                />
                <H6 className="m-0" style={{ fontWeight: 'bold' }}>
                  {t(val.title)}
                </H6>
              </div>
            ))}
            <H6 className="m-0 mt-4 mr-5 mb-5">{t('domain/instance/create.step2_string6')}</H6>
          </Grid>
          <Grid xs={12} lg={5} xl={4}>
            {renewData.map((val, index) => (
              <div
                onClick={() => handleValues('renew', val.id)}
                key={index}
                style={{ width: '100%', marginTop: '10px', display: 'flex', alignItems: 'center', cursor: 'pointer' }}
              >
                <Checkbox
                  checkbox={values.renew}
                  setCheckedValue={(value) => handleValues('renew', value)}
                  data={val}
                />
                <H6 className="m-0" style={{ fontWeight: 'bold' }}>
                  {t(val.title)}
                </H6>
              </div>
            ))}
            <H6 className="m-0 mt-4 mb-5">{t('domain/instance/create.step2_string7')}</H6>
          </Grid>
        </Grid>
      </Heading>
    </Styles.NewDomainStep2SC>
  );
};

export default DomainStep2;
