import { getLocalStorageValues } from 'constants/local-storage';
import { DOMAIN_CART, REMOVE_DOMAIN_CART, CLEAR_ALL_DOMAIN_CART, UPDATE_DOMAIN_CART } from 'store/types';
let { domain_cart } = getLocalStorageValues();

const initialState = {
  domain_deploy: domain_cart,
};

export default function (state = initialState, action) {
  let removeFromCart = [];
  if (action.type == REMOVE_DOMAIN_CART) {
    removeFromCart = state.domain_deploy.filter((_, index) => index != action.payload);
    localStorage.setItem('domain_cart', JSON.stringify([...removeFromCart]));
  }
  switch (action.type) {
    case DOMAIN_CART:
      return {
        ...state,
        domain_deploy: [...state.domain_deploy, { ...action.payload.domain_cart }],
      };
    case REMOVE_DOMAIN_CART:
      return {
        ...state,
        domain_deploy: [...removeFromCart],
      };
    case CLEAR_ALL_DOMAIN_CART:
      return {
        ...state,
        domain_deploy: [],
      };
    case UPDATE_DOMAIN_CART:
      return {
        ...state,
        domain_deploy: state.domain_deploy.map((item, index) => {
          if (index == action.payload.index) {
            return { ...item, term: action.payload.term };
          }
          return item;
        }),
      };
    default:
      return state;
  }
}
